import React from 'react';

import './image_grid.css';

const drawItem = (item) => {
  const image = item?.image?.fluid?.src;
  const alt = item?.image?.alt;
  const info = item.info || item.bildinfo;
  const filter = item.filter;

  return (
    <>
      {item?.image?.fluid ? (
        <div className="cardWrapperTall" key={item.collection_title}>
          <div className={`card-nocontent ${filter ? 'myfilter' : ''}`}>
            <img src={image} alt={alt} />
            {info ? <p className="imggridinfo">{info}</p> : null}
          </div>
        </div>
      ) : null}
    </>
  );
};

export default function ImageCollectionLarge(slice) {
  const text = slice.primary.text.text;
  const width = slice.primary.width;
  return (
    <section className={`${width === 'boxed' ?
      slice?.source ? 'temaCollection' : 'contentCollection' :
      'section-full-width bg-white py-20 sm:py-8 !sm:px-8'}`} key={Math.random()}>
      {getAlternatingRows(slice)}
      {text ? (
        <div className="image-collection-text-wrapper">
          <p className="image-collection-text">{text}</p>
        </div>
      ) : null}
    </section>
  );
}

const getAlternatingRows = (slice) => {
  const gridHtml = [];

  for (let i = 0; i < slice.items.length; i += 3) {
    if (slice.items[i + 2]) {
      gridHtml.push(
          <div key={i} className="card-row">
            {drawItem(slice.items[i])}
            {drawItem(slice.items[i + 1])}
            {drawItem(slice.items[i + 2])}
          </div>,
      );
      continue;
    }
    if (slice.items[i + 1]) {
      gridHtml.push(
          <div key={i} className="card-row">
            {drawItem(slice.items[i])}
            {drawItem(slice.items[i + 1])}
          </div>,
      );
    } else {
      gridHtml.push(
          <div key={i} className="card-row">
            {drawItem(slice.items[i])}
          </div>,
      );
    }
  }
  return gridHtml;
};
