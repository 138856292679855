import {Link} from 'gatsby';
import PropTypes from 'prop-types';
import React from 'react';

import {createLangShortcut} from '../../../utils/createLangShortcut';
import {getLanguageFromUrl} from '../../../utils/getLanguageFromUrl';
import {getReadMoreString} from '../../../utils/getReadMoreString';
import imageSize from '../../../utils/imageSize.js';

export default function FeatureSplitColorTight(sliceInfo) {
  const internalLink = sliceInfo.primary.lank.uid;
  const externalLink = sliceInfo.primary.lank.raw.url;
  const linkLang = sliceInfo.primary.lank.lang;
  const title = sliceInfo.primary.rubrik?.text || sliceInfo.title || sliceInfo.correctSlice?.primary.hero_title?.text;
  const kicker = sliceInfo.primary?.feature_kicker?.text;
  const text = sliceInfo.primary?.text?.html;
  const image = sliceInfo.primary.bild?.fluid.src || sliceInfo?.image || sliceInfo.correctSlice?.primary.hero_image.url;
  const alt = sliceInfo.primary.bild?.alt;
  const imgUrl = imageSize(image, 800);
  const line = sliceInfo?.primary?.line;
  const filter = sliceInfo?.primary?.filter || sliceInfo?.primary?.filter_select;
  const language = getLanguageFromUrl();

  return (
    <section className={sliceInfo?.source ?? 'tema content-split-color'}>
      <div className="feature-split-color">
        <div className="feature-content">
          <div className="feature-content-inner">
            {kicker && (
              <h4
                className="text-kicker font-semibold"
                style={!image ? {color: 'black'} : null}
                dangerouslySetInnerHTML={{__html: kicker}}
              ></h4>
            )}
            <h3 className="font-sm font-semibold" dangerouslySetInnerHTML={{__html: title}}></h3>
            {text ? <div dangerouslySetInnerHTML={{__html: text}} /> : null}
            {line ? <div className="featunderline"></div> : null}
            {internalLink ? (
              <Link to={'/' + createLangShortcut(linkLang) + '/' + internalLink}>
                <button>{sliceInfo.primary.call_to_action || getReadMoreString(language)}</button>
              </Link>
            ) : externalLink ? (
              <a href={externalLink} target="_blank" rel="noreferrer">
                <button>{sliceInfo.primary.call_to_action || getReadMoreString(language)}</button>
              </a>
            ) : null}
          </div>
        </div>
        <div className={`feature-img ${filter === 'standard' ? 'myfilter' : ''}`}>
          {imgUrl ? <img src={image} alt={alt} /> : null}
        </div>
      </div>
    </section>
  );
}

FeatureSplitColorTight.propTypes = {
  sliceInfo: PropTypes.object.isRequired,
};
