exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-da-js": () => import("./../../../src/pages/index.da.js" /* webpackChunkName: "component---src-pages-index-da-js" */),
  "component---src-pages-index-de-js": () => import("./../../../src/pages/index.de.js" /* webpackChunkName: "component---src-pages-index-de-js" */),
  "component---src-pages-index-en-js": () => import("./../../../src/pages/index.en.js" /* webpackChunkName: "component---src-pages-index-en-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-index-no-js": () => import("./../../../src/pages/index.no.js" /* webpackChunkName: "component---src-pages-index-no-js" */),
  "component---src-pages-index-sv-js": () => import("./../../../src/pages/index.sv.js" /* webpackChunkName: "component---src-pages-index-sv-js" */),
  "component---src-pages-preview-js": () => import("./../../../src/pages/preview.js" /* webpackChunkName: "component---src-pages-preview-js" */),
  "component---src-pages-unpublished-preview-js": () => import("./../../../src/pages/unpublishedPreview.js" /* webpackChunkName: "component---src-pages-unpublished-preview-js" */),
  "component---src-templates-content-js": () => import("./../../../src/templates/content.js" /* webpackChunkName: "component---src-templates-content-js" */),
  "component---src-templates-upplevelse-js": () => import("./../../../src/templates/upplevelse.js" /* webpackChunkName: "component---src-templates-upplevelse-js" */)
}

