import PropTypes from 'prop-types';
import ImageFullWidth from './Image/ImageFullWidth';
import ImageSmall from './Image/ImageSmall';

const Image = ({slice}) => {
  const {primary} = slice;

  if (!primary) {
    return null;
  }

  const variant = primary.image_variant.toLowerCase();

  switch (variant) {
    case 'full width':
      return ImageFullWidth(slice);
    default:
      return ImageSmall(slice);
  }
};

Image.propTypes = {
  slice: PropTypes.object,
};

export default Image;
