export const createLangShortcut = (lang) => {
  let langString = '';
  switch (lang) {
    case 'sv-se':
      langString = 'sv';
      break;
    case 'sv':
      langString = 'sv';
      break;
    case 'en-gb':
      langString = 'en';
      break;
    case 'en-us':
      langString = 'en';
      break;
    case 'en':
      langString = 'en';
      break;
    case 'no':
      langString = 'no';
      break;
    case 'da-dk':
      langString = 'da';
      break;
    case 'de-de':
      langString = 'de';
      break;
    case 'de':
      langString = 'de';
      break;
    case 'da':
      langString = 'da';
      break;
    default:
      langString = 'en';
  }
  return langString;
};
