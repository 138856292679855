/* eslint-disable new-cap */
import {graphql, useStaticQuery} from 'gatsby';
import {useMergePrismicPreviewData} from 'gatsby-plugin-prismic-previews';
import PropTypes from 'prop-types';

import {HeroFragment} from '../../fragments';
import {getCollectionArticles} from '../../utils/getCollectionArticles';
import CollectionAlternatingLargeList from './Collection/CollectionAlternatingLargeList';
import CollectionGridSmall from './Collection/CollectionGridSmall';
import CollectionListAlternating from './Collection/CollectionListAlternating';
import FullWidth3Items from './Collection/FullWidth3Items';
import FullWidth4Items from './Collection/FullWidth4Items';

const Collection = ({slice}) => {
  const staticData = useStaticQuery(graphql`
    query {
      allPrismicContent {
        edges {
          node {
            uid
            _previewable
            data {
              body {
                ...HeroFragment
              }
            }
          }
        }
      }
    }
  `);

  // eslint-disable-next-line no-unused-vars
  const {data, isPreview} = useMergePrismicPreviewData(staticData);

  const {primary} = slice;

  slice = getCollectionArticles(slice, data);

  if (!primary) {
    return null;
  }

  const variant = primary.collection_variant.toLowerCase();

  switch (variant) {
    case 'small grid':
      return CollectionGridSmall(slice);
    case 'alternating list':
      return CollectionListAlternating(slice);
    case 'alternating large list':
      return CollectionAlternatingLargeList(slice);
    case 'full width 3 items':
      return FullWidth3Items(slice);
    case 'full width 4 items':
      return FullWidth4Items(slice);
    default:
      return CollectionListAlternating(slice);
  }
};

Collection.fragments = [HeroFragment];

Collection.propTypes = {
  slice: PropTypes.object,
};

export default Collection;
