import React from 'react';

import './image.css';

export default function ImageSmall(slice) {
  const str = slice.primary.image_image?.fluid?.src;
  const bgColor = slice.primary.background_color === 'bg-color' ? true : false;
  const filter = slice.primary?.filter;
  const alt = slice.primary.image_image?.alt || slice.primary.image_caption;
  const bildinfo = slice.primary.bildinfo?.text || slice.primary.image_image?.copyright;
  return (
    <section className={bgColor ? 'bg-section content ' + slice?.source : 'content ' + slice?.source}>
      <div className={`${bgColor ? 'bg-color-variant' : ''} ${filter ? 'myfilter' : ''}`}>
        <img src={str} className="image" alt={alt}></img>
        <p className="bildtext">{slice.primary.bildtext?.text}</p>
        <p className="bildinfo">{bildinfo}</p>
      </div>
    </section>
  );
}
