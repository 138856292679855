import {Link} from 'gatsby';
import React from 'react';

import {createLangShortcut} from '../../../utils/createLangShortcut';
import Carousel from '../../Carousel';
import './grid_large.css';
import './slice_collection_small.css';

const drawItem = (item, className) => {
  const linkedHero = item.correctSlice?.primary;
  const image = item.collection_image?.fluid?.src || linkedHero?.collection_image?.fluid?.src;
  const alt = item.collection_image?.alt;
  const title = item.collection_title || linkedHero?.hero_title.text;
  const text = item?.collection_description?.html;
  const localLink = item?.collection_link.uid;
  const externalLink = item.collection_link.raw.url;
  const linkLang = item.collection_link.lang;
  const bildText = item?.bildtext[0]?.text || item.bildtext.text;
  const bildInfo = item?.bildinfo[0]?.text || item.bildinfo.text || item.collection_image?.copyright;
  const filter = item.filter;
  const filterSelect = item?.filter_select;
  const vimeoVideo = item?.vimeo_video?.embed_url;
  let segments = [];
  let videoId = '';
  if (vimeoVideo) {
    segments = vimeoVideo.split('/');
    videoId = segments[segments.length - 1];
  }

  return (
    <div className={className} key={title}>
      <div className={`img-area item-half ${filter ? 'myfilter' : ''} ${filterSelect ? 'filter-' + filterSelect : ''}`}>
        {image ? <img src={image} alt={alt} /> : null}
        {vimeoVideo ? (
          <div className="vimeo-video-wrapper collection-vimeo">
            <iframe
              title={title}
              // eslint-disable-next-line max-len
              src={`https://player.vimeo.com/video/${videoId}?speed=0&autoplay=1&controls=0&background=1&quality=720p&loop=1&muted=1}`}
              width="640"
              height="360"
              frameBorder="0"
              webkitallowfullscreen
              mozallowfullscreen
              allowFullScreen
            ></iframe>
          </div>
        ) : null}
        <p className="bildtext">{bildText}</p>
        <p className="bildinfo">{bildInfo}</p>
      </div>
      <div className={`content-area item-half ${text ? 'content-area-with-paragraph' : null}`}>
        <div className="content-area-inner">
          {externalLink || localLink ? (
            <Link
              to={localLink ? '/' + createLangShortcut(linkLang) + '/' + localLink : externalLink}
              target={externalLink ? '_blank' : null}
            >
              <h4 className="linkcolor font-medium">{title}</h4>
              <span className="large-list-separator"></span>
            </Link>
          ) : (
            <>
              <h4 className="font-medium">{title}</h4>
              <span className="large-list-separator"></span>
            </>
          )}
          <div className="collection-text" dangerouslySetInnerHTML={{__html: text}}></div>
        </div>
      </div>
    </div>
  );
};

export default function CollectionAlternatingLargeList(slice) {
  const bgColor = slice.primary.background_variant === 'bg-color' ? true : false;
  const carousel = slice.primary.mobil_karusell === true ? true : false;
  const collectiontitle = slice?.primary?.collection_main_title?.text;
  const collectioningress = slice?.primary?.ingress?.text;

  return (
    <>
      <section
        className={`${
          bgColor ? (slice?.source ? 'bg-section tema' : 'bg-section content') : slice?.source ? 'tema' : 'content'
        } ${carousel ? 'hidden-by-carousel ' : ' '}`}
        key={Math.random()}
      >
        <div className="destination-grid-heading">
          {collectiontitle ? <h2 className="font-medium">{collectiontitle}</h2> : null}
          {collectioningress ? <p className="mx-auto">{collectioningress}</p> : null}
        </div>
        {getAlternatingRows(slice)}
      </section>
      <section
        className={`${
          bgColor ?
            slice?.source ?
              'bg-section tema carousel-section' :
              'bg-section content carousel-section' :
            slice?.source ?
            'tema carousel-section' :
            'content carousel-section'
        } ${carousel ? 'carousel-active ' : ' '}`}
        key={Math.random()}
      >
        <div className="destination-grid-heading-mobile">
          {collectiontitle ? <h2 className="font-medium">{collectiontitle}</h2> : null}
          {collectioningress ? <p className="mx-auto">{collectioningress}</p> : null}
        </div>
        <Carousel>{getCarouselRows(slice)}</Carousel>
      </section>
    </>
  );
}

const getAlternatingRows = (slice) => {
  const gridHtml = [];

  for (let i = 0; i < slice.items.length; i += 2) {
    if (slice.items[i + 1]) {
      gridHtml.push(
          <div key={i} className="list-large">
            {drawItem(slice.items[i], 'item alt-item')}
            {drawItem(slice.items[i + 1], 'item-right alt-item')}
          </div>,
      );
    } else {
      gridHtml.push(
          <div key={i} className="list-large">
            {drawItem(slice.items[i], 'item alt-item')}
          </div>,
      );
    }
  }
  return gridHtml;
};

const getCarouselRows = (slice) => {
  const gridHtml = [];

  for (let i = 0; i < slice.items.length; i++) {
    gridHtml.push(
        <div key={i} className="list-large">
          {drawItem(slice.items[i], 'item')}
        </div>,
    );
  }

  return gridHtml;
};
