import {Link} from 'gatsby';
import PropTypes from 'prop-types';
import React from 'react';

import {createLangShortcut} from '../utils/createLangShortcut';

export default function LinkButton({localLink, externalLink, linkLang, children}) {
  return (
    <>
      {localLink ? (
        <Link to={'/' + createLangShortcut(linkLang) + '/' + localLink}>{children}</Link>
      ) : externalLink ? (
        <a href={externalLink} target="_blank" rel="noreferrer">
          {children}
        </a>
      ) : (
        <>{children}</>
      )}
    </>
  );
}

LinkButton.propTypes = {
  localLink: PropTypes.string,
  externalLink: PropTypes.string,
  linkLang: PropTypes.string,
  children: PropTypes.any,
};
