import {PrismicPreviewProvider, componentResolverFromMap} from 'gatsby-plugin-prismic-previews';
import * as React from 'react';

import PageTemplate from './src/templates/PagePreview';
import './src/css/index.css';
import './src/components/slices/Collection/collection.css';
import './src/components/slices/Collection/lists.css';
import './src/components/slices/Collection/slice_collection_small.css';
import './src/components/slices/Hero/hero.css';
import './src/components/landingpage_slices/Feature/feature.css';
import './src/components/slices/Feature/feature.css';

export const wrapRootElement = ({element}) => (
  <PrismicPreviewProvider
    initialEnabled={true}
    repositoryConfigs={[
      {
        repositoryName: 'visithalland2',
        linkResolver: require('./src/utils/linkResolver').linkResolver,
        componentResolver: componentResolverFromMap({
          page: PageTemplate,
        }),
      },
    ]}
  >
    {element}
  </PrismicPreviewProvider>
);
