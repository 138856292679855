import PropTypes from 'prop-types';
import React from 'react';

const Text = ({slice}) => {
  return (
    <section
      className={'text-field tema'}
      dangerouslySetInnerHTML={{
        __html: slice.primary?.text?.html || slice.primary?.text[0]?.html || slice.primary.html,
      }}
    />
  );
};

Text.propTypes = {
  slice: PropTypes.object,
};

export default Text;
