import PropTypes from 'prop-types';
import ImageCollectionLarge from './ImageCollection/ImageCollectionLarge';
import ImageCollectionMasonry from './ImageCollection/ImageCollectionMasonry';
import ImageCollectionSmall from './ImageCollection/ImageCollectionSmall';

const ImageCollection = ({slice}) => {
  const {primary} = slice;

  if (!primary) {
    return null;
  }

  const variant = primary.variant.toLowerCase();

  switch (variant) {
    case 'large':
      return ImageCollectionLarge(slice);
    case 'masonry':
      return ImageCollectionMasonry(slice);
    default:
      return ImageCollectionSmall(slice);
  }
};

ImageCollection.propTypes = {
  slice: PropTypes.object,
};

export default ImageCollection;
