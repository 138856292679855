import {graphql, useStaticQuery} from 'gatsby';
import {useMergePrismicPreviewData, withPrismicPreview} from 'gatsby-plugin-prismic-previews';
import PropTypes from 'prop-types';
import React from 'react';

import {
  ActionCall as LpActionCall,
  Collection as LpCollection,
  Destination as LpDestination,
  Feature as LpFeature,
  Header as LpHeader,
  Hero as LpHero,
  Line as LpLine,
  Quote as LpQuote,
  Text as LpText,
} from '../components/landingpage_slices';
import {
  Author,
  Collection,
  Destination,
  Divider,
  Feature,
  Hero,
  Image,
  ImageCollection,
  Instagram,
  Line,
  Quote,
  SectionHeader,
  Text,
  Video,
} from '../components/slices';
import FlerArtiklar from '../components/tema_slices/FlerArtiklar';

const PagePreview = (props) => {
  const previewData = props.location.state?.data ? JSON.parse(props.location.state.data) : null;
  const staticData = useStaticQuery(graphql`
    query {
      content: allPrismicContent {
        edges {
          node {
            uid
            lang
            dataRaw
            _previewable
            data {
              title
              body {
                ...HeroFragment
              }
              taggar {
                text
              }
            }
            lang
          }
        }
        totalCount
      }
      allPrismicUpplevelse {
        edges {
          node {
            uid
            lang
            _previewable
            data {
              body {
                ... on PrismicUpplevelseDataBodyHero {
                  id
                  primary {
                    hero_image {
                      url
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `);

  const {data, isPreview} = useMergePrismicPreviewData(staticData);

  const drawSlices = (slice) => {
    slice.source = 'tema';
    switch (slice.slice_type) {
      case 'hero':
        return Hero(slice);
      case 'feature':
        return Feature(slice);
      case 'text':
        return Text(slice);
      case 'image':
        return Image(slice);
      case 'collection':
        return Collection(slice);
      case 'section_header':
        return SectionHeader(slice);
      case 'quote':
        return Quote(slice);
      case 'video':
        return Video(slice);
      case 'instagram':
        return Instagram(slice);
      case 'divider':
        return Divider(slice);
      case 'fler_tema_artiklar':
        return FlerArtiklar(slice, previewData.PrismicUpplevelse.uid);
      case 'image_collection':
        return ImageCollection(slice);
      case 'author':
        return Author(slice);
      case 'line':
        return Line(slice);
      case 'destination_grid':
        return Destination(slice);
      default:
        return <p key={slice.id}>#</p>;
    }
  };

  const drawLandingpageSlices = (slice) => {
    switch (slice.slice_type) {
      case 'hero':
        return LpHero(slice);
      case 'feature':
        return LpFeature(slice);
      case 'collection':
        return LpCollection(slice, data);
      case 'section_header':
        return LpHeader(slice);
      case 'call_to_action':
        return LpActionCall(slice);
      case 'quote':
        return LpQuote(slice);
      case 'text':
        return LpText(slice);
      case 'line':
        return LpLine(slice);
      case 'destination_grid':
        return LpDestination(slice);
      default:
        return null;
    }
  };

  return (
    <div>
      {previewData?.prismicContent?.data.body.map((slice) => drawSlices(slice))}
      {previewData?.PrismicUpplevelse?.data.body.map((slice) => drawSlices(slice))}
      {previewData?.PrismicLandingpage?.data.body.map((slice) => drawLandingpageSlices(slice))}
    </div>
  );
};

PagePreview.propTypes = {
  props: PropTypes.object,
};

export default withPrismicPreview(PagePreview);
