import React from 'react';

import imageSizeBig from '../../../utils/imageSizeBig.js';
import './herosplit.css';

export default function HeroFullWidthQuote(slice) {
  const image = slice.primary.hero_image?.fluid?.src;
  const imgUrl = imageSizeBig(image, 1600);
  const title = slice.primary?.hero_title?.text;
  const text = slice.primary?.hero_introduction?.text;
  const quote = slice.primary?.quote?.text;

  return (
    <section className="section-full-width section-px hero-quote flex sm:flex-col" key={Math.random()}>
      <div className="w-1/2 sm:w-full h-full sm:h-auto flex flex-col justify-around">
        <div className="pr-20 sm:pr-0 pb-10 md:pb-0">
          <h1 className="text-black text-3xl md:text-4xl font-semibold uppercase mt-0">{title}</h1>
          <p className="text-black font-fira text-base mb-0">{text}</p>
        </div>
        <div>
          <p className="text-black text-3xl md:text-4xl font-fira mb-10 md:mb-0 italic pr-10 sm:pr-0">{quote}</p>
        </div>
        <div className="h-20 hidden md:block"></div>
      </div>
      <div
        className="w-1/2 sm:w-full h-full sm:h-420 bg-cover bg-no-repeat bg-top"
        style={{backgroundImage: `url('${imgUrl}')`}}
      ></div>
    </section>
  );
}
