import {Link, graphql, useStaticQuery} from 'gatsby';
import {useMergePrismicPreviewData} from 'gatsby-plugin-prismic-previews';
import PropTypes from 'prop-types';
import React, {useEffect} from 'react';

import {createLangShortcut} from '../../utils/createLangShortcut';
import '../layout/slice_search.css';

export default function Search({filteredArr, searchTerm, currentLang}) {
  const staticData = useStaticQuery(graphql`
    query Searchbar {
      allPrismicMenu {
        nodes {
          lang
          _previewable
          data {
            sokresultat
            artiklar
          }
        }
      }
    }
  `);

  // eslint-disable-next-line no-unused-vars
  const {data, isPreview} = useMergePrismicPreviewData(staticData);

  let texts = {};
  let flag = false;

  if (currentLang) {
    for (let i = 0; i < data.allPrismicMenu.nodes.length; i++) {
      if (data.allPrismicMenu.nodes[i].lang === currentLang) {
        texts = data.allPrismicMenu.nodes[i].data;
        flag = true;
      }
    }
    if (flag === false) {
      texts = data.allPrismicMenu.nodes[3].data;
    }
  } else {
    for (let i = 0; i < data.allPrismicMenu.nodes.length; i++) {
      if (data.allPrismicMenu.nodes[i].lang === 'sv-se') {
        texts = data.allPrismicMenu.nodes[i].data;
        flag = true;
      }
    }
  }

  window.scrollTo({top: 0});

  useEffect(() => {
    document.body.classList.remove('locked-scroll');
  }, []);

  return (
    <>
      <section className="headerdivider tema searchresults mt-8">
        <h4 className="underline">
          {texts.sokresultat} {searchTerm}: {filteredArr.length} {texts.artiklar}
        </h4>
      </section>
      <section className="search-grid">
        {filteredArr.map(({node}) => (
          <Link key={node.uid} to={'/' + createLangShortcut(currentLang) + '/' + node.uid}>
            <div className="result-item">
              <span>
                <div className="itemtext2">
                  <h3 className="gridfont">
                    {node.data?.title || node.data?.titel?.text || node.data?.body[0]?.primary?.hero_title?.text}
                  </h3>
                </div>
              </span>
              {node.data.body[0].primary?.hero_image.fluid?.src ? (
                <img
                  className="heroimage"
                  src={node.data.body[0].primary.hero_image.fluid.src}
                  alt={node.data.body[0]?.primary?.hero_image?.alt}
                />
              ) : (
                <div className="heroimage" />
              )}
            </div>
          </Link>
        ))}
      </section>
    </>
  );
}

Search.propTypes = {
  filteredArr: PropTypes.array,
  searchTerm: PropTypes.string,
  currentLang: PropTypes.string,
};
