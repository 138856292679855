import {Link} from 'gatsby';
import PropTypes from 'prop-types';
import React from 'react';

import {createLangShortcut} from '../../../utils/createLangShortcut';
import imageSizeBig from '../../../utils/imageSizeBig.js';

export default function HeroFeatureSplitLeft(sliceInfo) {
  const image = sliceInfo?.primary.hero_image?.url;
  const alt = sliceInfo.primary.hero_image?.alt;
  const imgUrl = imageSizeBig(image, 1600);
  const title = sliceInfo.primary?.hero_title?.text;
  const imgOverlay = sliceInfo?.primary?.hero_overlay_image?.url;
  const altImgOverlay = sliceInfo?.primary?.hero_overlay_image?.alt;
  const localLink = sliceInfo.primary?.lank?.uid;
  const externalLink = sliceInfo.primary?.lank?.raw?.url;
  const linkLang = sliceInfo.primary?.lank?.lang;
  const additionalLocalLink = sliceInfo.primary?.ytterligare_lank?.uid;
  const additionalExternalLink = sliceInfo.primary?.ytterligare_lank?.url;
  const additionalLinkLang = sliceInfo.primary?.ytterligare_lank?.lang;
  const action = sliceInfo?.primary?.action?.text;
  const additionalAction = sliceInfo?.primary?.ytterligare_action?.text;
  const text = sliceInfo?.primary?.hero_introduction?.html;
  const vimeoVideo = sliceInfo?.primary?.hero_video?.embed_url;
  let segments = [];
  let videoId = '';
  if (vimeoVideo) {
    segments = vimeoVideo.split('/');
    videoId = segments[segments.length - 1];
  }

  return (
    <section className={'hero hero-split hero-feature hero-feature-left'} key={Math.random()}>
      <div className="hero-split-wrapper">
        <div className="hero-img-split-wrapper">
          {imgUrl ? <img src={image} alt={alt} className="heroimagesplit" /> : <div className="heroimagesplit" />}
          {sliceInfo.primary?.hero_video ? (
            <video autoPlay muted loop id="myVideo">
              <source src={sliceInfo.primary.hero_video} type="video/mp4" />
              Your browser does not support HTML5 video.
            </video>
          ) : null}
          {vimeoVideo ? (
            <div className="vimeo-video-wrapper">
              <iframe
                title={title}
                src={
                  // eslint-disable-next-line max-len
                  `https://player.vimeo.com/video/${videoId}?speed=0&autoplay=1&controls=0&background=1&quality=720p&loop=1&muted=1`
                }
                width="640"
                height="360"
                frameBorder="0"
                webkitallowfullscreen
                mozallowfullscreen
                allowFullScreen
              ></iframe>
            </div>
          ) : null}
        </div>
        <div className="hero-text-split-wrapper">
          <div className={imgOverlay ? 'hero-text-split hero-overlay-split w-8/10' : 'hero-text-split w-8/10'}>
            {imgOverlay ? (
              <div className="hero-split-img-overlay">
                <img src={imgOverlay} alt={altImgOverlay} className="splitoverlayimg mx-auto max-w-sm mb-8" />
                <h2>{title}</h2>
                <div className="hero-overlay-view elements-center">
                  {text ? <div className="text-black" dangerouslySetInnerHTML={{__html: text}} /> : null}
                </div>
                <div className="d-block cta-buttons">
                  {localLink ? (
                    <Link to={'/' + createLangShortcut(linkLang) + '/' + localLink}>
                      <button>{action}</button>
                    </Link>
                  ) : externalLink ? (
                    <a href={externalLink}>
                      <button>{action}</button>
                    </a>
                  ) : null}
                  {additionalLocalLink ? (
                    <Link to={'/' + createLangShortcut(additionalLinkLang) + '/' + additionalLocalLink}>
                      <button>{additionalAction}</button>
                    </Link>
                  ) : additionalExternalLink ? (
                    <a href={additionalExternalLink}>
                      <button>{additionalAction}</button>
                    </a>
                  ) : null}
                </div>
              </div>
            ) : (
              <>
                <h2>{title}</h2>
                {text ? <div className="text-black" dangerouslySetInnerHTML={{__html: text}} /> : null}
                <div className="d-block cta-buttons">
                  {localLink ? (
                    <Link className="mr-3" to={'/' + createLangShortcut(linkLang) + '/' + localLink}>
                      <button>{action}</button>
                    </Link>
                  ) : externalLink ? (
                    <a href={externalLink} target="_blank" rel="noreferrer" className="mr-3">
                      <button>{action}</button>
                    </a>
                  ) : null}
                  {additionalLocalLink ? (
                    <Link
                      className="ml-3"
                      to={'/' + createLangShortcut(additionalLinkLang) + '/' + additionalLocalLink}
                    >
                      <button>{additionalAction}</button>
                    </Link>
                  ) : additionalExternalLink ? (
                    <a className="ml-3" href={additionalExternalLink} target="_blank" rel="noreferrer">
                      <button>{additionalAction}</button>
                    </a>
                  ) : null}
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </section>
  );
}

HeroFeatureSplitLeft.propTypes = {
  sliceInfo: PropTypes.object.isRequired,
};
