import {Link} from 'gatsby';
import React from 'react';

import {createLangShortcut} from '../../../utils/createLangShortcut';
import {cutText} from '../../../utils/cutText';
import imageSize from '../../../utils/imageSize.js';
import Carousel from '../../Carousel';

const drawItem = (item) => {
  const linkedHero = item.correctSlice?.primary;
  const image = item.collection_image?.fluid?.src || linkedHero?.collection_image?.fluid?.src;
  const alt = item.collection_image?.alt;
  const imgUrl = imageSize(image, 800);
  const title = item.collection_title || linkedHero?.hero_title.text;
  const text = item.collection_description?.text || linkedHero?.hero_introduction.text;
  const localLink = item?.collection_link.uid;
  const externalLink = item.collection_link.raw.url;
  const linkLang = item.collection_link.lang;
  const filter = item.filter;
  const filterSelect = item?.filter_select;
  const vimeoVideo = item?.vimeo_video?.embed_url;
  let segments = [];
  let videoId = '';
  if (vimeoVideo) {
    segments = vimeoVideo.split('/');
    videoId = segments[segments.length - 1];
  }

  return (
    <div className="item" key={title}>
      <div className={`img-area ${filter ? 'myfilter' : ''} ${filterSelect ? 'filter-' + filterSelect : ''}`}>
        {imgUrl ? <img src={image} alt={alt} /> : null}
        {vimeoVideo ? (
          <div className="vimeo-video-wrapper collection-vimeo">
            <iframe
              title={title}
              // eslint-disable-next-line max-len
              src={`https://player.vimeo.com/video/${videoId}?speed=0&autoplay=1&controls=0&background=1&quality=720p&loop=1&muted=1}`}
              width="640"
              height="360"
              frameBorder="0"
              webkitallowfullscreen
              mozallowfullscreen
              allowFullScreen
            ></iframe>
          </div>
        ) : null}
      </div>
      <div className="content-area">
        {localLink ? (
          <Link to={'/' + createLangShortcut(linkLang) + '/' + localLink}>
            <h4 className="linkcolor">{title}</h4>
          </Link>
        ) : externalLink ? (
          <a href={externalLink} target="_blank" rel="noreferrer">
            <h4 className="linkcolor">{title}</h4>
          </a>
        ) : (
          <h4 className="font-medium">{title}</h4>
        )}
        <p>{text ? cutText(text, 250) : null}</p>
      </div>
    </div>
  );
};

export default function CollectionListSmall(slice) {
  const bgColor = slice.primary.background_variant === 'green' ? true : false;
  const carousel = slice.primary.mobil_karusell === true ? true : false;
  const collectiontitle = slice?.primary?.collection_main_title?.text;
  const collectioningress = slice?.primary?.ingress?.text;

  return (
    <>
      <section
        className={`${carousel ? 'hidden-by-carousel ' : ' '} ${
          slice?.source ? (bgColor ? 'bg-section tema' : 'tema ') : bgColor ? 'bg-section content' : 'content'
        }`}
        key={Math.random()}
      >
        <div className="destination-grid-heading">
          {collectiontitle ? <h2 className="font-medium">{collectiontitle}</h2> : null}
          {collectioningress ? <p className="mx-auto">{collectioningress}</p> : null}
        </div>
        <div className="list-small">{slice.items.map((item) => drawItem(item))}</div>
      </section>
      <section
        className={`${carousel ? 'carousel-active ' : ' '} ${
          slice?.source ?
            bgColor ?
              'bg-section tema carousel-section' :
              'tema carousel-section' :
            bgColor ?
            'bg-section content carousel-section' :
            'content carousel-section'
        }`}
        key={Math.random()}
      >
        <div className="destination-grid-heading-mobile">
          {collectiontitle ? <h2 className="font-medium">{collectiontitle}</h2> : null}
          {collectioningress ? <p className="mx-auto">{collectioningress}</p> : null}
        </div>
        <div className="list-small">
          <Carousel>{slice.items.map((item) => drawItem(item))}</Carousel>
        </div>
      </section>
    </>
  );
}
