import PropTypes from 'prop-types';
import React from 'react';

const SingleImage = ({item}) => {
  const text = item.text || item.bildtext;
  const info = item.info || item.bildinfo || item.image?.copyright;
  const alt = item.image?.alt;
  const filter = item.filter;
  return (
    <figure key={text} className={`masonry-brick masonry-brick--h ${filter ? 'myfilter' : ''}`}>
      {item?.image?.fluid ? <img src={item.image.fluid.src} alt={alt} /> : null}
      {text ? <p className="imggridtext">{text}</p> : null}
      {info ? <p className="imggridinfo">{info}</p> : null}
    </figure>
  );
};

export default SingleImage;

SingleImage.propTypes = {
  item: PropTypes.object.isRequired,
};
