import PropTypes from 'prop-types';
import React from 'react';

import arrowBlack from '../../../images/arrow-right-black.svg';
import arrow from '../../../images/arrow-right.svg';
import LinkButton from '../../LinkButton';

export default function FeatureFullWidthImage(sliceInfo) {
  const bgVariant = sliceInfo.primary?.background_variant;
  const img = sliceInfo.primary?.bild?.url;
  const header = sliceInfo.primary?.full_width_header?.text;
  const subheader = sliceInfo.primary?.full_width_text?.text;
  const title = sliceInfo.primary?.rubrik?.text;
  const kicker = sliceInfo.primary?.feature_kicker?.text;
  const description = sliceInfo.primary?.text?.text;
  const localLink = sliceInfo.primary?.lank?.uid;
  const externalLink = sliceInfo.primary?.lank?.raw?.url;
  const linkLang = sliceInfo.primary?.lank?.lang;
  const callToAction = sliceInfo.primary?.call_to_action;
  const position = sliceInfo.primary?.full_width_position;
  const mobileSpacing = sliceInfo.primary?.mobile_bottom_spacing;
  const fullWidth = sliceInfo.primary?.mobile_full_width;
  const textVariant = sliceInfo.primary?.full_width_text_color;
  const filter = sliceInfo.primary?.filter || sliceInfo.primary?.filter_select;

  return (
    <section
      key={sliceInfo.uid}
      className={
        `section-full-width bg-${bgVariant} py-20 sm:py-10 ` +
        `${fullWidth ? 'mobile-nopadding mobile-py' : ''} ${!mobileSpacing ? 'sm:pb-0' : ''}`
      }
    >
      {header && (
        <div className={`w-full mb-10 sm:px-8 ${fullWidth ? 'pt-0 md:pt-10' : ''}`}>
          <h2
            className="text-black text-3xl md:text-4xl font-semibold uppercase mt-0"
            dangerouslySetInnerHTML={{__html: header}}
          />
          <p className="text-black font-fira text-base mb-0" dangerouslySetInnerHTML={{__html: subheader}} />
        </div>
      )}
      <LinkButton localLink={localLink} externalLink={externalLink} linkLang={linkLang}>
        <div className={`full-width-image relative group overflow-hidden`}>
          <div
            className={`bg-cover bg-no-repeat bg-center transition-all duration-500 hover:scale-105 full-width-image ${
              filter ? 'filter-' + filter : ''
            }`}
            style={{backgroundImage: `url('${img}')`}}
          ></div>
          <div
            className={
              `absolute bottom-10 sm:bottom-5 ` +
              `${position === 'left' ? 'left-10 sm:left-8 right-8' : 'right-10 sm:right-8'}`
            }
          >
            {kicker && (
              <h4
                className={`text-kicker font-semibold text-${textVariant}`}
                dangerouslySetInnerHTML={{__html: kicker}}
              ></h4>
            )}
            <h2
              className={`text-3xl md:text-4xl text-${textVariant} font-semibold uppercase mb-0  ${kicker && 'mt-0'}`}
              dangerouslySetInnerHTML={{__html: title}}
            ></h2>
            <p className={`text-base text-${textVariant} mb-3`} dangerouslySetInnerHTML={{__html: description}}></p>
            {callToAction && (localLink || externalLink) && (
              <button
                className={
                  `bg-transparent hover:bg-transparent hover:text-${textVariant} p-0 ` +
                  `uppercase font-rift text-${textVariant} text-base font-semibold flex`
                }
              >
                {callToAction}
                {textVariant === 'white' ? (
                  <img className="ml-2 group-hover:ml-4 transition-all duration-200" src={arrow} alt="" />
                ) : (
                  <img className="ml-2 group-hover:ml-4 transition-all duration-200" src={arrowBlack} alt="" />
                )}
              </button>
            )}
          </div>
        </div>
      </LinkButton>
    </section>
  );
}

FeatureFullWidthImage.propTypes = {
  sliceInfo: PropTypes.object.isRequired,
};
