import PropTypes from 'prop-types';
import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';

export default function Carousel({children}) {
  const settings = {
    dots: true,
    infinite: false,
    arrows: true,
    speed: 600,
    slidesToShow: 1,
    slidesToScroll: 1,
    centerMode: true,
    centerPadding: '0px',
    cssEase: 'ease-in-out',
  };

  return <Slider {...settings}>{children}</Slider>;
}

Carousel.propTypes = {
  children: PropTypes.any,
};
