export const getCollectionArticles = (slice, data) => {
  slice.items.forEach((sliceItem) => {
    const correctArticle = data.allPrismicContent.edges.find(
        (item) => item.node.uid === sliceItem.collection_link?.uid || item.node.uid === sliceItem.lank?.uid,
    );
    if (correctArticle) {
      const correctSlice = correctArticle.node.data.body.find((item) => item.slice_type === 'hero');
      sliceItem.correctSlice = correctSlice;
    }
  });
  return slice;
};
