import {Link, graphql, useStaticQuery} from 'gatsby';
import {useMergePrismicPreviewData} from 'gatsby-plugin-prismic-previews';
import PropTypes from 'prop-types';
import React from 'react';

import {HeroFragment} from '../../../fragments';
import {createLangShortcut} from '../../../utils/createLangShortcut';
import {getLinkedArticle} from '../../../utils/getLinkedArticle';
import imageSizeBig from '../../../utils/imageSizeBig.js';
import '../slice_feature_video.css';

export default function HeroFeatureFullWidth(sliceInfo) {
  const staticData = useStaticQuery(graphql`
    query {
      allPrismicContent {
        edges {
          node {
            uid
            lang
            _previewable
            data {
              body {
                ...HeroFragment
              }
            }
          }
        }
      }
    }
  `);

  // eslint-disable-next-line no-unused-vars
  const {data, isPreview} = useMergePrismicPreviewData(staticData);

  sliceInfo = getLinkedArticle(sliceInfo, data);

  const image = sliceInfo?.primary.hero_image?.url;
  const alt = sliceInfo.primary.bakgrundsbild?.alt;
  const imgUrl = imageSizeBig(image, 1600);
  const title =
    sliceInfo.primary.rubrik?.text ||
    sliceInfo.title ||
    sliceInfo.primary?.hero_title?.text ||
    sliceInfo.correctSlice?.primary.hero_title?.text;
  const imgOverlay = sliceInfo.primary.grafik?.url;
  const altImgOverlay = sliceInfo.primary.grafik?.alt;
  const localLink = sliceInfo.primary?.lank?.uid;
  const externalLink = sliceInfo.primary?.lank?.raw?.url;
  const linkLang = sliceInfo.primary?.lank?.lang;
  const additionalLocalLink = sliceInfo.primary?.ytterligare_lank?.uid;
  const additionalExternalLink = sliceInfo.primary?.ytterligare_lank?.url;
  const additionalLinkLang = sliceInfo.primary?.ytterligare_lank?.lang;
  const action = sliceInfo?.primary?.action?.text;
  const additionalAction = sliceInfo?.primary?.ytterligare_action?.text;
  const text = sliceInfo?.primary?.introduction?.html;
  const vimeoVideo = sliceInfo?.primary?.vimeo_video?.embed_url;
  const filter = sliceInfo?.primary?.filter_select || sliceInfo?.primary?.filter;
  let segments = [];
  let videoId = '';
  if (vimeoVideo) {
    segments = vimeoVideo.split('/');
    videoId = segments[segments.length - 1];
  }

  return (
    <section key={`hero-${sliceInfo.uid}`} className={`filter-${filter} hero hero-feature landing-hero`}>
      {imgUrl ? <img src={image} className="heroimage" alt={alt} /> : <div className="heroimage" />}
      {vimeoVideo ? (
        <div className="vimeo-video-wrapper">
          <iframe
            title={title}
            src={
              // eslint-disable-next-line max-len
              `https://player.vimeo.com/video/${videoId}?speed=0&autoplay=1&controls=0&background=1&quality=720p&loop=1&muted=1`
            }
            width="640"
            height="360"
            frameBorder="0"
            webkitallowfullscreen
            mozallowfullscreen
            allowFullScreen
          ></iframe>
        </div>
      ) : null}
      {sliceInfo?.video ? (
        <video autoPlay muted loop id="myVideo">
          <source src={sliceInfo.video} type="video/mp4" />
          Your browser does not support HTML5 video.
        </video>
      ) : null}
      <span>
        <div className="feature-text">
          {imgOverlay ? <img src={imgOverlay} className="mx-auto max-w-sm mb-8" alt={altImgOverlay} /> : null}
          {title && !imgOverlay ? <h2 className="page-title font-semibold">{title}</h2> : null}
          {text ? <div dangerouslySetInnerHTML={{__html: text}} /> : null}
          {localLink ? (
            <Link to={'/' + createLangShortcut(linkLang) + '/' + localLink}>
              <button>{action}</button>
            </Link>
          ) : externalLink ? (
            <a href={externalLink} target="_blank" rel="noreferrer">
              <button>{action}</button>
            </a>
          ) : null}
          {additionalLocalLink ? (
            <Link to={'/' + createLangShortcut(additionalLinkLang) + '/' + additionalLocalLink}>
              <button>{additionalAction}</button>
            </Link>
          ) : additionalExternalLink ? (
            <a href={additionalExternalLink} target="_blank" rel="noreferrer">
              <button>{additionalAction}</button>
            </a>
          ) : null}
        </div>
      </span>
    </section>
  );
}

HeroFeatureFullWidth.fragments = [HeroFragment];

HeroFeatureFullWidth.propTypes = {
  sliceInfo: PropTypes.object.isRequired,
};
