exports.linkResolver = (doc) => {
  // Backup for all other types
  let lang = '/sv/';
  const uid = doc.uid ? doc.uid : '';
  if (doc.lang === 'en-us') {
    lang = '/en/';
  }
  if (doc.lang === 'no') {
    lang = '/no/';
  }
  if (doc.lang === 'de-de') {
    lang = '/de/';
  }
  return `${lang}${uid}`;
};
