export const getLinkedArticle = (slice, data) => {
  const correctArticle = data?.allPrismicContent?.edges.find(
      (item) => item.node.uid === slice.primary?.feature_link?.uid || item.node.uid === slice.primary?.lank?.uid,
  );
  if (correctArticle) {
    const correctSlice = correctArticle.node.data.body.find((item) => item.slice_type === 'hero');
    if (correctSlice) {
      slice.correctSlice = correctSlice;
    }
  }
  return slice;
};
