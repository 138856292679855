import {Link} from 'gatsby';
import React from 'react';

import {createLangShortcut} from '../../../utils/createLangShortcut';
import {cutText} from '../../../utils/cutText';
import imageSize from '../../../utils/imageSize.js';
import Carousel from '../../Carousel';
import './card_collection.css';
import './slice_collection_small.css';

const drawItem = (item) => {
  const linkedHero = item.correctSlice?.primary;
  const image = item.collection_image?.fluid?.src || linkedHero?.collection_image?.fluid?.src;
  const alt = item.collection_image?.alt;
  const imgUrl = imageSize(image, 800);
  const title = item.collection_title || linkedHero?.hero_title.text;
  const localLink = item?.collection_link.uid;
  const externalLink = item.collection_link.raw.url;
  const linkLang = item.collection_link.lang;
  const bgVariant = item?.background_variant;
  const text = item?.collection_description?.text;
  const filter = item.filter;
  const filterSelect = item?.filter_select;
  const vimeoVideo = item?.vimeo_video?.embed_url;
  let segments = [];
  let videoId = '';
  if (vimeoVideo) {
    segments = vimeoVideo.split('/');
    videoId = segments[segments.length - 1];
  }

  return (
    <div
      className={bgVariant ? bgVariant + ' bg-collection cardWrapper small-grid' : 'cardWrapper small-grid'}
      key={item.collection_title}
    >
      {localLink ? (
        <Link to={'/' + createLangShortcut(linkLang) + '/' + localLink}>
          <div
            className={`${imgUrl ? 'card-small-img' : 'card-small-noimg'} card-small-grid ${
              text ? 'card' : 'card-nocontent'
            }`}
          >
            {title ? (
              <div className="itemtext">
                <h4 className="font-medium">{title}</h4>
                {text && bgVariant ? (
                  <div className="collection-text" dangerouslySetInnerHTML={{__html: text}}></div>
                ) : null}
              </div>
            ) : null}
            {imgUrl ? (
              <div className={`collection-img-wrapper ${filter ? 'myfilter' : ''} `+
              `${filterSelect ? 'filter-' + filterSelect : ''}`}>
                <img src={image} alt={alt} />
              </div>
            ) : (
              <div className="small-grid-placeholder"></div>
            )}
            {vimeoVideo ? (
              <div className="vimeo-video-wrapper collection-vimeo">
                <iframe
                  title={title}
                  // eslint-disable-next-line max-len
                  src={`https://player.vimeo.com/video/${videoId}?speed=0&autoplay=1&controls=0&background=1&quality=720p&loop=1&muted=1}`}
                  width="640"
                  height="360"
                  frameBorder="0"
                  webkitallowfullscreen
                  mozallowfullscreen
                  allowFullScreen
                ></iframe>
              </div>
            ) : null}
            {text && !bgVariant ? (
              <div className="collection-text small-collection-text">
                <p>{cutText(text, 250)}</p>
              </div>
            ) : null}
          </div>
        </Link>
      ) : externalLink ? (
        <a href={externalLink} target="_blank" rel="noreferrer">
          <div
            className={`${imgUrl ? 'card-small-img' : 'card-small-noimg'} card-small-grid ${
              text ? 'card' : 'card-nocontent'
            }`}
          >
            {title ? (
              <div className="itemtext">
                <h4 className="font-medium">{title}</h4>
                {text && bgVariant ? (
                  <div className="collection-text" dangerouslySetInnerHTML={{__html: text}}></div>
                ) : null}
              </div>
            ) : null}
            {image ? (
              <div className={`collection-img-wrapper ${filter ? 'myfilter' : ''}`}>
                <img src={image} alt={alt} />
              </div>
            ) : (
              <div className="small-grid-placeholder"></div>
            )}
            {vimeoVideo ? (
              <div className="vimeo-video-wrapper collection-vimeo">
                <iframe
                  title={title}
                  // eslint-disable-next-line max-len
                  src={`https://player.vimeo.com/video/${videoId}?speed=0&autoplay=1&controls=0&background=1&quality=720p&loop=1&muted=1}`}
                  width="640"
                  height="360"
                  frameBorder="0"
                  webkitallowfullscreen
                  mozallowfullscreen
                  allowFullScreen
                ></iframe>
              </div>
            ) : null}
            {text && !bgVariant ? (
              <div className="collection-text small-collection-text">
                <p>{cutText(text, 250)}</p>
              </div>
            ) : null}
          </div>
        </a>
      ) : (
        <div
          className={`${imgUrl ? 'card-small-img' : 'card-small-noimg'} card-small-grid ${
            text ? 'card' : 'card-nocontent'
          }`}
        >
          {title ? (
            <div className="itemtext">
              <h4 className="font-medium">{title}</h4>
              {text && bgVariant ? (
                <div className="collection-text" dangerouslySetInnerHTML={{__html: text}}></div>
              ) : null}
            </div>
          ) : null}
          {image ? (
            <div className={`collection-img-wrapper ${filter ? 'myfilter' : ''}`}>
              <img src={image} alt={alt} />
            </div>
          ) : (
            <div className="small-grid-placeholder"></div>
          )}
          {vimeoVideo ? (
            <div className="vimeo-video-wrapper collection-vimeo">
              <iframe
                title={title}
                // eslint-disable-next-line max-len
                src={`https://player.vimeo.com/video/${videoId}?speed=0&autoplay=1&controls=0&background=1&quality=720p&loop=1&muted=1}`}
                width="640"
                height="360"
                frameBorder="0"
                webkitallowfullscreen
                mozallowfullscreen
                allowFullScreen
              ></iframe>
            </div>
          ) : null}
          {text && !bgVariant ? (
            <div className="collection-text small-collection-text">
              <p>{cutText(text, 250)}</p>
            </div>
          ) : null}
        </div>
      )}
    </div>
  );
};

export default function CollectionGridSmall(slice) {
  const bgColor = slice.primary.background_variant === 'bg-color' ? true : false;
  const carousel = slice.primary.mobil_karusell === true ? true : false;
  const collectiontitle = slice?.primary?.collection_main_title?.text;
  const collectioningress = slice?.primary?.ingress?.text;

  return (
    <>
      <section
        className={`${carousel ? 'hidden-by-carousel ' : ' '} ${
          bgColor ?
            slice?.source ?
              'bg-section temaCollection small-grid-section' :
              'bg-section contentCollection small-grid-section ' :
            slice?.source ?
            'temaCollection ' :
            'contentCollection '
        }`}
        key={Math.random()}
      >
        <div className="destination-grid-heading">
          {collectiontitle ? <h2 className="font-medium">{collectiontitle}</h2> : null}
          {collectioningress ? <p className="mx-auto">{collectioningress}</p> : null}
        </div>
        <div className="small-grid-wrapper">
          <div className="destination-grid-heading-mobile">
            {collectiontitle ? <h2 className="font-medium">{collectiontitle}</h2> : null}
            {collectioningress ? <p className="mx-auto">{collectioningress}</p> : null}
          </div>
          {getAlternatingRows(slice)}
        </div>
      </section>
      <section
        className={`${carousel ? 'carousel-active ' : ' '} ${
          bgColor ?
            slice?.source ?
              'bg-section temaCollection small-grid-section carousel-section' :
              'bg-section contentCollection small-grid-section carousel-section' :
            slice?.source ?
            'temaCollection carousel-section' :
            'contentCollection carousel-section'
        }`}
        key={Math.random()}
      >
        <Carousel>{getCarouselRows(slice)}</Carousel>
      </section>
    </>
  );
}

const getAlternatingRows = (slice) => {
  const gridHtml = [];

  for (let i = 0; i < slice.items.length; i += 3) {
    if (slice.items[i + 2]) {
      gridHtml.push(
          <div key={i} className="card-row small-grid-row three-items-in-row">
            {drawItem(slice.items[i])}
            {drawItem(slice.items[i + 1])}
            {drawItem(slice.items[i + 2])}
          </div>,
      );
      continue;
    }
    if (slice.items[i + 1]) {
      gridHtml.push(
          <div key={i} className="card-row small-grid-row">
            {drawItem(slice.items[i])}
            {drawItem(slice.items[i + 1])}
          </div>,
      );
    } else {
      gridHtml.push(
          <div key={i} className="card-row small-grid-row">
            {drawItem(slice.items[i])}
          </div>,
      );
    }
  }

  return gridHtml;
};

const getCarouselRows = (slice) => {
  const gridHtml = [];

  for (let i = 0; i < slice.items.length; i++) {
    gridHtml.push(
        <div key={i} className="card-row small-grid-row ">
          {drawItem(slice.items[i])}
        </div>,
    );
  }

  return gridHtml;
};
