import React from 'react';

import './hero.css';

export default function Author(author) {
  return (
    <>
      <div className="author-left">
        <img className="hero-author" src={author.editor_image.url} alt=""></img>
      </div>
      <div className="author-right">
        <p className="author-name">{author.editor_name}</p>
        <br />
        <p className="author-title" style={{color: 'LightGray'}}>
          {author.editor_role}
        </p>
      </div>
    </>
  );
}
