import {Link, graphql, useStaticQuery} from 'gatsby';
import {useMergePrismicPreviewData} from 'gatsby-plugin-prismic-previews';
import PropTypes from 'prop-types';
import React from 'react';

import {HeroFragment} from '../../../fragments';
import {createLangShortcut} from '../../../utils/createLangShortcut';
import {getCollectionArticles} from '../../../utils/getCollectionArticles';
import {getLanguageFromUrl} from '../../../utils/getLanguageFromUrl';
import {getReadMoreString} from '../../../utils/getReadMoreString';
import imageSize from '../../../utils/imageSize.js';
import Carousel from '../../Carousel';

export default function CollectionListAlternating(slice) {
  const staticData = useStaticQuery(graphql`
    query {
      allPrismicContent {
        edges {
          node {
            uid
            _previewable
            data {
              body {
                ...HeroFragment
              }
            }
          }
        }
      }
    }
  `);

  // eslint-disable-next-line no-unused-vars
  const {data, isPreview} = useMergePrismicPreviewData(staticData);

  slice = getCollectionArticles(slice, data);
  const drawItem = () => {
    const gridHtml = [];

    for (let i = 0; i < slice.items.length; i += 2) {
      if (slice.items[i + 1]) {
        gridHtml.push(
            <div key={i} className="itemrow">
              {drawCollectionItem(slice.items[i], 'wrapper wrapper-big')}
              {drawCollectionItem(slice.items[i + 1], 'wrapper wrapper-small')}
            </div>,
        );
      } else {
        gridHtml.push(
            <div key={i} className="itemrow">
              {drawCollectionItem(slice.items[i], 'wrapper wrapper-full')}
            </div>,
        );
      }
    }
    return gridHtml;
  };

  const carouselItem = () => {
    const gridHtml = [];

    for (let i = 0; i < slice.items.length; i++) {
      gridHtml.push(
          <div key={i} className="itemrow">
            {drawCollectionItem(slice.items[i], 'wrapper wrapper-full')}
          </div>,
      );
    }
    return gridHtml;
  };

  const bgColor = slice.primary?.background_variant === 'green' ? true : false;
  const carousel = slice.primary.mobil_karusell === true ? true : false;

  return (
    <>
      <section className={`grid ${carousel ? 'hidden-by-carousel ' : ' '} ${bgColor ? 'bg-section' : ''}`}>
        {drawItem()}
      </section>
      <section
        className={`grid carousel-section ${carousel ? 'carousel-active ' : ' '} ${bgColor ? 'bg-section' : ''}`}
      >
        <Carousel>{carouselItem()}</Carousel>
      </section>
    </>
  );
}

CollectionListAlternating.propTypes = {
  slice: PropTypes.object.isRequired,
};

const drawCollectionItem = (slice, classNames) => {
  const image = slice.bild.url;
  const alt = slice.bild?.alt;
  const imgUrl = imageSize(image, 1600);
  const localLink = slice?.lank?.uid;
  const externalLink = slice?.lank?.raw?.url;
  const linkLang = slice.lank?.lang;
  const title = slice.titel?.text;
  const bgVariant = slice.background_variant;
  const text = slice.text?.html;
  const filter = slice.filter || slice.filter_select;
  const vimeoVideo = slice?.vimeo_video?.embed_url;
  let segments = [];
  let videoId = '';
  if (vimeoVideo) {
    segments = vimeoVideo.split('/');
    videoId = segments[segments.length - 1];
  }
  const language = getLanguageFromUrl();

  return (
    <div className={classNames}>
      <div
        className={`${
          !image ? 'item-grid bg-collection list-alternating ' + bgVariant : 'item-grid myfilter list-alternating'
        } ${title ? null : 'list-alternating-no-title'}`}
      >
        <Link
          to={
            localLink ? '/' + createLangShortcut(linkLang) + '/' + slice?.lank.uid : externalLink ? externalLink : null
          }
          target={externalLink ? '_blank' : null}
        >
          <span>
            {title ? (
              <div className="itemtext">
                <h4 className="gridfont" style={!image ? {color: 'black'} : null}>
                  {title}
                </h4>
                <button>{slice.call_to_action ? slice.call_to_action : getReadMoreString(language)}</button>
                {text ? <div className="collection-text" dangerouslySetInnerHTML={{__html: text}}></div> : null}
              </div>
            ) : null}
            {!image ? null : (
              <div className="photoby">
                <p>{slice.bild?.copyright}</p>
              </div>
            )}
          </span>
          {!imgUrl ? null : (
            <div className={`collection-img-wrapper ${filter ? 'myfilter' : ''}`}>
              <img className="heroimage" src={image} alt={alt} />
            </div>
          )}
          {vimeoVideo ? (
            <div className="vimeo-video-wrapper collection-vimeo">
              <iframe
                title={title}
                // eslint-disable-next-line max-len
                src={`https://player.vimeo.com/video/${videoId}?speed=0&autoplay=1&controls=0&background=1&quality=720p&loop=1&muted=1`}
                width="640"
                height="360"
                frameBorder="0"
                webkitallowfullscreen
                mozallowfullscreen
                allowFullScreen
              ></iframe>
            </div>
          ) : null}
        </Link>
      </div>
    </div>
  );
};

CollectionListAlternating.fragments = [HeroFragment];
