import PropTypes from 'prop-types';
import React from 'react';

import arrow from '../../images/arrow-right.svg';
import CarouselFullWidth from '../CarouselFullWidth';
import LinkButton from '../LinkButton';

const FullWidthGrid = ({slice}) => {
  const bgVariant = slice.primary?.fwg_background_variant;
  const header = slice.primary?.fwg_header?.text;
  const subheader = slice.primary?.fwg_text?.text;
  const items = slice.items;
  return (
    <section key={slice.uid} className={`section-full-width bg-${bgVariant} py-20 sm:py-10 mobile-nopadding`}>
      {header && (
        <div className="w-full mb-10 sm:px-8">
          <h2 className="text-black text-3xl md:text-4xl font-semibold uppercase mt-0">{header}</h2>
          <p className="text-black font-fira text-base mb-0">{subheader}</p>
        </div>
      )}
      {items && (
        <div className="w-full flex space-x-8 sm:hidden">
          <div className="w-3/4 box-border flex flex-col justify-between space-y-8">
            <GridItem item={items[0]} height="h-522" />
            <div className="w-full flex space-x-8">
              <div className="w-1/2 box-border">
                <GridItem item={items[1]} height="h-310" />
              </div>
              <div className="w-1/2 box-border">
                <GridItem item={items[2]} height="h-310" />
              </div>
            </div>
          </div>
          <div className="w-1/4 box-border">
            <GridItem item={items[3]} height="h-870" />
          </div>
        </div>
      )}
      {items && (
        <div className="sm:flex carousel-active">
          <CarouselFullWidth>
            {items.map((item, index) => (
              <GridItem key={`fwg-${index}`} item={item} height="h-420" />
            ))}
          </CarouselFullWidth>
        </div>
      )}
    </section>
  );
};

FullWidthGrid.propTypes = {
  slice: PropTypes.object.isRequired,
};

const GridItem = ({item, height}) => {
  return (
    <LinkButton localLink={item.fwg_lank.uid} externalLink={item.fwg_lank.raw.url} linkLang={item.fwg_lank.lang}>
      <div className={`overflow-hidden ${height} relative group`}>
        <div
          className={`bg-cover bg-no-repeat bg-center ${height} relative transition-all duration-500 hover:scale-105
         ${item.fwg_filter ? 'filter-' + item.fwg_filter : ''} ` +
         `${item.fwg_filter_select ? 'filter-' + item.fwg_filter_select : ''}`}
          style={{backgroundImage: `url('${item.fwg_bild?.url}')`}}
        ></div>
        <div className="absolute bottom-8 left-8 w-full">
          <h2 className="text-3xl text-white font-semibold uppercase mb-0">{item.fwg_titel.text}</h2>
          <button
            className={
              `bg-transparent hover:bg-transparent hover:text-white p-0 uppercase ` +
              `font-rift text-white text-base font-semibold flex w-1/2 lg:w-fit`
            }
          >
            {item.fwg_call_to_action.text}
            <img className="ml-2 group-hover:ml-4 transition-all duration-200" src={arrow} alt="" />
          </button>
        </div>
      </div>
    </LinkButton>
  );
};

GridItem.propTypes = {
  item: PropTypes.object.isRequired,
  height: PropTypes.string.isRequired,
};

export default FullWidthGrid;
