import {Link} from 'gatsby';
import React from 'react';

import {createLangShortcut} from '../../../utils/createLangShortcut';
import Carousel from '../../Carousel';
import './grid_large.css';
import './slice_collection_small.css';

const drawItem = (item, className) => {
  const linkedHero = item.correctSlice?.primary;
  const image = item.collection_image?.fluid?.src || linkedHero?.collection_image?.fluid?.src;
  const alt = item.collection_image?.alt;
  const title = item.collection_title;
  const localLink = item?.collection_link.uid;
  const externalLink = item.collection_link.raw.url;
  const linkLang = item.collection_link.lang;
  const bildText = item?.bildtext[0]?.text || item.bildtext.text;
  const bildinfo = item?.bildinfo[0]?.text || item.bildinfo.text;
  const bgVariant = item?.background_variant;
  const text = item?.collection_description?.html;
  const filter = item.filter;
  const filterSelect = item?.filter_select;
  const vimeoVideo = item?.vimeo_video?.embed_url;
  let segments = [];
  let videoId = '';
  if (vimeoVideo) {
    segments = vimeoVideo.split('/');
    videoId = segments[segments.length - 1];
  }

  return (
    <div className={className}>
      <div
        className={`${
          !image ? 'item-grid bg-collection bg-collection ' + bgVariant : 'item-grid myfilter'
        } list-alternating ${title ? null : 'list-alternating-no-title'}`}
      >
        <span>
          {title ? (
            <div className="itemtext">
              {localLink || externalLink ? (
                <Link
                  to={localLink ? '/' + createLangShortcut(linkLang) + '/' + localLink : externalLink}
                  target={externalLink ? '_blank' : null}
                >
                  {' '}
                  <h4 className="gridfont font-medium" style={!image ? {color: 'black'} : null}>
                    {title}
                  </h4>{' '}
                </Link>
              ) : (
                <h4 className="gridfont font-medium" style={!image ? {color: 'black'} : null}>
                  {title}
                </h4>
              )}
              {text ? <div className="collection-text" dangerouslySetInnerHTML={{__html: text}}></div> : null}
            </div>
          ) : (
            <Link
              to={localLink ? '/' + createLangShortcut(linkLang) + '/' + localLink : externalLink}
              target={externalLink ? '_blank' : null}
            ></Link>
          )}
          {!image ? null : (
            <div className="photoby">
              <p>{bildText}</p>
              <p className="credit">{bildinfo}</p>
            </div>
          )}
        </span>
        {!image ? null : (
          <div className={`collection-img-wrapper ${filter ? 'myfilter' : ''} ` +
          `${filterSelect ? 'filter-' + filterSelect : ''}`}>
            <img className="heroimage" src={image} alt={alt} />
          </div>
        )}
        {vimeoVideo ? (
          <div className="vimeo-video-wrapper collection-vimeo">
            <iframe
              title={title}
              // eslint-disable-next-line max-len
              src={`https://player.vimeo.com/video/${videoId}?speed=0&autoplay=1&controls=0&background=1&quality=720p&loop=1&muted=1}`}
              width="640"
              height="360"
              frameBorder="0"
              webkitallowfullscreen
              mozallowfullscreen
              allowFullScreen
            ></iframe>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default function CollectionListAlternating(slice) {
  const bgColor = slice.primary.background_variant === 'bg-color' ? true : false;
  const carousel = slice.primary.mobil_karusell === true ? true : false;
  const collectiontitle = slice?.primary?.collection_main_title?.text;
  const collectioningress = slice?.primary?.ingress?.text;

  return (
    <>
      <section
        className={`${carousel ? 'hidden-by-carousel ' : ' '} ${
          bgColor ?
            slice?.source ?
              'temaCollection bg-section' :
              'contentCollection bg-section' :
            slice?.source ?
            'temaCollection' :
            'contentCollection'
        }`}
        key={Math.random()}
      >
        <div className="destination-grid-heading">
          {collectiontitle ? <h2 className="font-medium">{collectiontitle}</h2> : null}
          {collectioningress ? <p className="mx-auto">{collectioningress}</p> : null}
        </div>
        {getAlternatingRows(slice)}
      </section>
      <section
        className={`${carousel ? 'carousel-active ' : ' '} ${
          bgColor ?
            slice?.source ?
              'temaCollection bg-section carousel-section' :
              'contentCollection bg-section carousel-section' :
            slice?.source ?
            'temaCollection carousel-section' :
            'contentCollection carousel-section'
        }`}
        key={Math.random()}
      >
        <div className="destination-grid-heading-mobile">
          {collectiontitle ? <h2 className="font-medium">{collectiontitle}</h2> : null}
          {collectioningress ? <p className="mx-auto">{collectioningress}</p> : null}
        </div>
        <Carousel>{getCarouselRows(slice)}</Carousel>
      </section>
    </>
  );
}

const getAlternatingRows = (slice) => {
  const gridHtml = [];

  for (let i = 0; i < slice.items.length; i += 2) {
    if (slice.items[i + 1]) {
      gridHtml.push(
          <div key={i} className="itemrow">
            {drawItem(slice.items[i], 'wrapper wrapper-big')}
            {drawItem(slice.items[i + 1], 'wrapper wrapper-small')}
          </div>,
      );
    } else {
      gridHtml.push(
          <div key={i} className="itemrow">
            {drawItem(slice.items[i], 'wrapper wrapper-full')}
          </div>,
      );
    }
  }

  return gridHtml;
};

const getCarouselRows = (slice) => {
  const gridHtml = [];

  for (let i = 0; i < slice.items.length; i++) {
    gridHtml.push(
        <div key={i} className="itemrow">
          {drawItem(slice.items[i], 'wrapper wrapper-full')}
        </div>,
    );
  }

  return gridHtml;
};
