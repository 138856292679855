import {Link} from 'gatsby';
import React from 'react';

import {createLangShortcut} from '../../../utils/createLangShortcut';
import './sectionHeader.css';

export default function SectionHeaderLine(slice) {
  const text = slice.primary.section_header_body[0]?.text || slice.primary.section_header_body.text;
  const title = slice.primary.section_header_title[0]?.text || slice.primary.section_header_title.text;
  const bgColor = slice.primary.background_color === 'bg-color' ? true : false;
  const localLink = slice.primary?.lank?.uid;
  const externalLink = slice.primary?.lank?.raw?.url;
  const linkLang = slice.primary?.lank?.lang;
  const action = slice?.primary?.action?.text;
  const line = slice.primary?.line;

  return (
    <section className={bgColor ? 'bg-section content line ' + slice?.source : 'content line ' + slice?.source}>
      <div className={bgColor ? 'bg-color-variant' : ''}>
        <h2 className={line ? 'underline' : 'underline line-hidden'}>{title ? title : null}</h2>
        {text ? <p>{text}</p> : null}
        {localLink ? (
          <Link to={'/' + createLangShortcut(linkLang) + '/' + localLink}>
            <button>{action}</button>
          </Link>
        ) : externalLink ? (
          <a href={externalLink} target="_blank" rel="noreferrer">
            <button>{action}</button>
          </a>
        ) : null}
      </div>
    </section>
  );
}
