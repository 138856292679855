import PropTypes from 'prop-types';
import React from 'react';

import {getLanguageFromUrl} from '../../../utils/getLanguageFromUrl';
import {getReadMoreString} from '../../../utils/getReadMoreString';
import arrow from '../../../images/arrow-right-black.svg';
import arrowRight from '../../../images/arrow-right.png';
import CarouselFullWidth from '../../CarouselFullWidth';
import LinkButton from '../../LinkButton';

export default function CollectionFullWidth3Items(sliceInfo) {
  const bgVariant = sliceInfo.primary?.background_variant;
  const buttonVariant = sliceInfo.primary?.button_variant;
  const header = sliceInfo.primary?.collection_main_title?.text;
  const subheader = sliceInfo.primary?.ingress?.text;
  const callToAction = sliceInfo.primary?.collection_call_to_action?.text;
  const localLink = sliceInfo.primary?.collection_lank?.uid;
  const externalLink = sliceInfo.primary?.collection_lank?.url;
  const linkLang = sliceInfo.primary?.collection_lank?.lang;
  const items = sliceInfo.items;

  return (
    <section key={sliceInfo.uid} className={`section-full-width bg-${bgVariant} py-20 sm:py-10 mobile-nopadding`}>
      {header && (
        <div className="w-full mb-10 sm:px-8">
          <h2 className="text-black text-3xl md:text-4xl font-semibold uppercase mt-0">{header}</h2>
          <p className="text-black font-fira text-base mb-0">{subheader}</p>
          <div className={`flex ${buttonVariant === 'green' ? 'my-10 justify-center' : 'mb-10 mt-3'}`}>
            {buttonVariant === 'green' ? (
              <LinkButton localLink={localLink} externalLink={externalLink} linkLang={linkLang}>
                <button className="mx-auto px-10 hover:bg-green-base hover:text-white">{callToAction}</button>
              </LinkButton>
            ) : (
              <LinkButton localLink={localLink} externalLink={externalLink} linkLang={linkLang}>
                <button
                  className={
                    `bg-transparent hover:bg-transparent hover:text-black ` +
                    `p-0 uppercase font-rift text-black text-base font-semibold flex`
                  }
                >
                  {callToAction}
                  <img className="ml-2" src={arrow} alt="" />
                </button>
              </LinkButton>
            )}
          </div>
        </div>
      )}
      <div className="grid-custom grid-cols-3 gap-8 w-full">
        {items &&
          items.map((item, index) => (
            <div className="" key={`fw3-item-${index}`}>
              <GridItem item={item} index={index} />
            </div>
          ))}
      </div>
      {items && (
        <div className="sm:flex carousel-active">
          <CarouselFullWidth>
            {items.map((item, index) => (
              <GridItem key={`fwg-${index}`} item={item} />
            ))}
          </CarouselFullWidth>
        </div>
      )}
    </section>
  );
}

CollectionFullWidth3Items.propTypes = {
  sliceInfo: PropTypes.object.isRequired,
};

const GridItem = ({item, index}) => {
  const language = getLanguageFromUrl();
  return (
    <div key={index}>
      <LinkButton
        localLink={item.collection_link?.uid}
        externalLink={item.collection_link?.raw?.url}
        linkLang={item.collection_link?.lang}
      >
        <div className={`overflow-hidden group h-590 sm:h-420 relative group hidden md:block`}>
          <div
            className={
              `bg-cover bg-no-repeat bg-center transition-all duration-500 hover:scale-105 h-590 ` +
              `sm:h-420 relative ${item.filter_select ? 'filter-' + item.filter_select : ''} ` +
              `${item.filter && 'myfilter'}`
            }
            style={{backgroundImage: `url('${item.collection_image?.url}')`}}
          ></div>
          {item.hover ? (
            <>
              <div
                className="absolute bottom-5 md:left-5 left-8 group-hover:opacity-0 transition-all duration-100 ease-in"
              >
                <h2 className="text-3xl text-white font-semibold uppercase mb-0">{item.collection_title}</h2>
                <p className='text-white text-base uppercase font-rift text-underline md:hidden mb-0'>
                  {item?.show_more_text?.text ? item.show_more_text.text : 'Visa mer'}
                </p>
              </div>
              <div
                className={`absolute opacity-0 group-hover:opacity-100 -bottom-40 left-0 right-0 ` +
                `group-hover:bottom-0 transition-all duration-300 ease-in`}>
                <div className='p-5 bg-black bg-opacity-50 transition-all duration-300 ease-in'>
                  <h2 className="text-3xl text-white font-semibold uppercase mb-0 mt-0">
                    {item.collection_title}
                  </h2>
                  <p className={`text-white mb-2 text-base mt-2`}>
                    {item.collection_description.text}
                  </p>
                  <div className='flex'>
                    <span className='text-white text-base uppercase font-rift'>
                      {item?.button_text?.text ? item.button_text.text : getReadMoreString(language)}
                    </span>
                    <img className="ml-2" src={arrowRight} alt="" />
                  </div>
                </div>
              </div>
            </>
          ) : (
            <div className="absolute bottom-5 md:left-5 left-8">
              <h2 className="text-3xl text-white font-semibold uppercase mb-0">{item.collection_title}</h2>
            </div>
          )}
        </div>
      </LinkButton>
      <div className='block md:hidden'>
        <div className={`overflow-hidden group h-590 sm:h-420 relative group`}>
          <div
            className={
              `bg-cover bg-no-repeat bg-center transition-all duration-500 hover:scale-105 h-590 ` +
              `sm:h-420 relative ${item.filter_select ? 'filter-' + item.filter_select : ''}`
            }
            style={{backgroundImage: `url('${item.collection_image?.url}')`}}
          ></div>
          {item.hover ? (
            <>
              {item.collection_description.text ? (
                <>
                  <div
                    className={`absolute bottom-5 md:left-5 left-8 ` +
                    `group-hover:opacity-0 transition-all duration-100 ease-in`}
                  >
                    <h2 className="text-3xl text-white font-semibold uppercase mb-0">{item.collection_title}</h2>
                    <p className='text-white text-base uppercase font-rift text-underline md:hidden mb-0'>
                      {item?.show_more_text?.text ? item.show_more_text.text : 'Visa mer'}
                    </p>
                  </div>
                  <div
                    className={`absolute opacity-0 group-hover:opacity-100 -bottom-40 left-0 right-0 ` +
                    `group-hover:bottom-0 transition-all duration-300 ease-in`}>
                    <div className='p-5 bg-black bg-opacity-50 transition-all duration-300 ease-in'>
                      <h2 className="text-3xl text-white font-semibold uppercase mb-0 mt-0">
                        {item.collection_title}
                      </h2>
                      <p className={`text-white mb-2 text-base mt-2`}>
                        {item.collection_description.text}
                      </p>
                      <LinkButton
                        localLink={item.collection_link?.uid}
                        externalLink={item.collection_link?.raw?.url}
                        linkLang={item.collection_link?.lang}
                      >
                        <div className="flex">
                          <span className='text-white text-base uppercase font-rift'>
                            {item?.button_text?.text ? item.button_text.text : getReadMoreString(language)}
                          </span>
                          <img className="ml-2" src={arrowRight} alt="" />
                        </div>
                      </LinkButton>
                    </div>
                  </div>
                </>
              ) : (
                <div className="absolute bottom-5 md:left-5 left-8">
                  <LinkButton
                    localLink={item.collection_link?.uid}
                    externalLink={item.collection_link?.raw?.url}
                    linkLang={item.collection_link?.lang}
                  >
                    <h2 className="text-3xl text-white font-semibold uppercase mb-0">{item.collection_title}</h2>
                  </LinkButton>
                </div>
              )}
            </>
          ) : (
            <div className="absolute bottom-5 md:left-5 left-8">
              <LinkButton
                localLink={item.collection_link?.uid}
                externalLink={item.collection_link?.raw?.url}
                linkLang={item.collection_link?.lang}
              >
                <h2 className="text-3xl text-white font-semibold uppercase mb-0">{item.collection_title}</h2>
              </LinkButton>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

GridItem.propTypes = {
  item: PropTypes.object.isRequired,
  index: PropTypes.number,
};
