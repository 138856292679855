import PropTypes from 'prop-types';
import React from 'react';

import './text.css';

const Text = ({slice}) => {
  const bgColor = slice.primary.variant === 'bg-color' ? true : false;
  return (
    <section className={bgColor ? 'bg-section text-field ' + slice?.source : 'text-field ' + slice?.source}>
      <div
        className={bgColor ? 'bg-color-variant text-field-inner' : 'text-field-inner'}
        dangerouslySetInnerHTML={{
          __html: slice.primary?.text?.html || slice.primary?.text[0]?.html || slice.primary.html,
        }}
      ></div>
    </section>
  );
};

Text.propTypes = {
  slice: PropTypes.object,
};

export default Text;
