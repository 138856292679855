import {Link} from 'gatsby';
import React from 'react';

import {createLangShortcut} from '../../../utils/createLangShortcut';
import {getLanguageFromUrl} from '../../../utils/getLanguageFromUrl';
import {getReadMoreString} from '../../../utils/getReadMoreString';

export default function FeatureFull(slice) {
  const {primary} = slice;
  const internalLink = primary.feature_link.uid;
  const externalLink = primary.feature_link.raw.url;
  const linkLang = primary.feature_link.lang;
  const title = slice.primary?.feature_title;
  const kicker = slice.primary?.feature_kicker?.text;
  const brodtext = slice.primary.feature_body.text;
  const image = primary.feature_image?.url;
  const alt = primary.feature_image?.alt;
  const line = slice.primary?.line;
  const filter = slice.primary?.feature_filter_select || slice.primary?.filter;
  const language = getLanguageFromUrl();

  return (
    <section className={`hero feature-full-hero ${slice?.source} ${filter && 'myfilter'} ` +
    `${filter === 'standard' ? 'myfilter' : ''}`}>
      {image ? <img className="featureimage" src={image} alt={alt} /> : <div className="featureimage no-img"></div>}

      <span>
        <div className="feature-text">
          {kicker && (
            <h4
              className="text-kicker font-semibold"
              style={!image ? {color: 'black'} : null}
              dangerouslySetInnerHTML={{__html: kicker}}
            ></h4>
          )}
          {title ? (
            <h3
              className={`font-sm font-medium ${!image ? 'black-title' : null}`}
              dangerouslySetInnerHTML={{__html: title}}
            />
          ) : null}
          {brodtext ? <p dangerouslySetInnerHTML={{__html: brodtext}} /> : null}

          {line ? <div className="featunderline"></div> : null}
          {internalLink ? (
            <Link to={'/' + createLangShortcut(linkLang) + '/' + internalLink}>
              <button>{primary.feature_button_label || getReadMoreString(language)}</button>
            </Link>
          ) : externalLink ? (
            <a href={externalLink} target="_blank" rel="noreferrer">
              <button>{primary.feature_button_label || getReadMoreString(language)}</button>
            </a>
          ) : null}
        </div>
      </span>
    </section>
  );
}
