import {Link} from 'gatsby';
import PropTypes from 'prop-types';
import React from 'react';

import {createLangShortcut} from '../../../utils/createLangShortcut';
import imageSizeBig from '../../../utils/imageSizeBig.js';
import Author from './Author';

export default function HeroFullWidth(slice) {
  const image = slice.primary.hero_image?.url;
  const alt = slice.primary.hero_image?.alt;
  const imgUrl = imageSizeBig(image, 1920);

  const author = slice.primary?.hero_author?.document?.data;
  const imgOverlay = slice.primary.hero_overlay_image?.url || slice.primary.hero_overlay_image.fluid?.src;
  const altImgOverlay = slice.primary.hero_overlay_image?.alt;
  const localLink = slice.primary.lank.uid;
  const externalLink = slice.primary.lank.raw.url;
  const linkLang = slice.primary.lank.lang;
  const additionalLocalLink = slice.primary?.ytterligare_lank?.uid;
  const additionalExternalLink = slice.primary?.ytterligare_lank?.url || slice.primary?.ytterligare_lank?.raw?.url;
  const additionalLinkLang = slice.primary?.ytterligare_lank?.lang;
  const action = slice?.primary?.action?.text;
  const additionalAction = slice?.primary?.ytterligare_action?.text;
  const vimeoVideo = slice?.primary?.vimeo_video?.embed_url;
  const extraTitle = slice?.primary?.extra_title?.text;
  const heroIntroMobil = slice?.primary?.introduktion_mobil?.text;
  const filter = slice?.primary?.filter_select || slice?.primary?.filter;
  let segments = [];
  let videoId = '';
  if (vimeoVideo) {
    segments = vimeoVideo.split('/');
    videoId = segments[segments.length - 1];
  }

  return (
    <>
      <section className={`hero filter-${filter}`} key={Math.random()}>
        {imgUrl ? <img src={image} alt={alt} className="heroimage" /> : <div className="heroimage" />}
        {vimeoVideo ? (
          <div className="vimeo-video-wrapper">
            <iframe
              title={slice.primary.hero_title[0]?.text || slice.primary.hero_title.text}
              // eslint-disable-next-line max-len
              src={`https://player.vimeo.com/video/${videoId}?speed=0&autoplay=1&controls=0&background=1&quality=720p&loop=1&muted=1`}
              frameBorder="0"
              webkitallowfullscreen
              mozallowfullscreen
              allowFullScreen
            ></iframe>
          </div>
        ) : null}
        {slice.primary?.hero_video ? (
          <video autoPlay muted loop id="myVideo">
            <source src={slice.primary.hero_video} type="video/mp4" />
            Your browser does not support HTML5 video.
          </video>
        ) : null}

        <span>
          {imgOverlay ? (
            <div className="overlay-box">
              <img src={imgOverlay} alt={altImgOverlay} className="overlayimg" />
              <div className="hero-overlay-view elements-center">
                <h1>{slice.primary.hero_title[0]?.text || slice.primary.hero_title.text}</h1>
                <p className="hero_introduktion">
                  {slice.primary.hero_introduction[0]?.text || slice.primary.hero_introduction.text}
                </p>
                <p className="hero_introduktion_mobil">
                  {heroIntroMobil ? heroIntroMobil : slice.primary.hero_introduction.text}
                </p>
              </div>
              <div className="block cta-buttons-overlay">
                {localLink ? (
                  <Link to={'/' + createLangShortcut(linkLang) + '/' + localLink}>
                    <button>{action}</button>
                  </Link>
                ) : externalLink ? (
                  <a href={externalLink} target="_blank" rel="noreferrer">
                    <button>{action}</button>
                  </a>
                ) : null}
                {additionalLocalLink ? (
                  <Link to={'/' + createLangShortcut(additionalLinkLang) + '/' + additionalLocalLink}>
                    <button>{additionalAction}</button>
                  </Link>
                ) : additionalExternalLink ? (
                  <a href={additionalExternalLink} target="_blank" rel="noreferrer">
                    <button>{additionalAction}</button>
                  </a>
                ) : null}
              </div>
            </div>
          ) : (
            <div className="hero-text mobile-centered elements-center">
              {extraTitle ? <h3>{extraTitle}</h3> : null}

              <h1>{slice.primary.hero_title[0]?.text || slice.primary.hero_title.text}</h1>
              <p className="hero_introduktion">
                {slice.primary.hero_introduction[0]?.text || slice.primary.hero_introduction.text}
              </p>
              <p className="hero_introduktion_mobil">
                {heroIntroMobil ? heroIntroMobil : slice.primary.hero_introduction.text}
              </p>
              <div className="d-block cta-buttons">
                {localLink ? (
                  <Link to={'/' + createLangShortcut(linkLang) + '/' + localLink}>
                    <button>{action}</button>
                  </Link>
                ) : externalLink ? (
                  <a href={externalLink}>
                    <button>{action}</button>
                  </a>
                ) : null}
                {additionalLocalLink ? (
                  <Link to={'/' + createLangShortcut(additionalLinkLang) + '/' + additionalLocalLink}>
                    <button>{additionalAction}</button>
                  </Link>
                ) : additionalExternalLink ? (
                  <a href={additionalExternalLink}>
                    <button>{additionalAction}</button>
                  </a>
                ) : null}
              </div>
              {author ? Author(author) : null}
            </div>
          )}
        </span>
      </section>
    </>
  );
}

HeroFullWidth.propTypes = {
  slice: PropTypes.object.isRequired,
};
