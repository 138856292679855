import {Link} from 'gatsby';
import React from 'react';

import {createLangShortcut} from '../../../utils/createLangShortcut';
import imageSize from '../../../utils/imageSize.js';
import Carousel from '../../Carousel';

const drawItem = (item) => {
  const linkedHero = item.correctSlice?.primary;
  const image = item.bild?.fluid?.src || linkedHero?.bild?.fluid?.src;
  const alt = item.bild?.alt;
  const imgUrl = imageSize(image, 400);
  const title = item.titel.text || linkedHero?.hero_title.text;
  const localLink = item?.lank.uid;
  const externalLink = item.lank.raw.url;
  const linkLang = item.lank.lang;
  const bgVariant = item?.background_variant;
  const text = item.text?.html;
  const filter = item?.filter || item?.filter_select;
  const vimeoVideo = item?.vimeo_video?.embed_url;
  let segments = [];
  let videoId = '';
  if (vimeoVideo) {
    segments = vimeoVideo.split('/');
    videoId = segments[segments.length - 1];
  }

  return (
    <div
      className={bgVariant ? bgVariant + ' bg-collection cardWrapper small-grid' : 'cardWrapper small-grid'}
      key={item.collection_title}
    >
      {localLink ? (
        <Link to={'/' + createLangShortcut(linkLang) + '/' + localLink}>
          <div className={text ? 'card card-small-grid' : 'card-nocontent'}>
            {title ? (
              <div className="itemtext">
                <h4>{title}</h4>
                {text && !imgUrl ? (
                  <div className="collection-text" dangerouslySetInnerHTML={{__html: text}}></div>
                ) : null}
              </div>
            ) : null}
            {imgUrl ? (
              <div className={`collection-img-wrapper ${filter ? 'myfilter' : ''}`}>
                <img src={image} alt={alt} />
              </div>
            ) : null}
            {vimeoVideo ? (
              <div className="vimeo-video-wrapper collection-vimeo">
                <iframe
                  title={title}
                  // eslint-disable-next-line max-len
                  src={`https://player.vimeo.com/video/${videoId}?speed=0&autoplay=1&controls=0&background=1&quality=720p&loop=1&muted=1}`}
                  width="640"
                  height="360"
                  frameBorder="0"
                  webkitallowfullscreen
                  mozallowfullscreen
                  allowFullScreen
                ></iframe>
              </div>
            ) : null}
            {text && imgUrl ? (
              <div className="collection-text small-collection-text" dangerouslySetInnerHTML={{__html: text}}></div>
            ) : null}
          </div>
        </Link>
      ) : externalLink ? (
        <a href={externalLink} target="_blank" rel="noreferrer">
          <div className={text ? 'card card-small-grid' : 'card-nocontent'}>
            {title ? (
              <div className="itemtext">
                <h4>{title}</h4>
                {text && !imgUrl ? (
                  <div className="collection-text" dangerouslySetInnerHTML={{__html: text}}></div>
                ) : null}
              </div>
            ) : null}
            {image ? (
              <div className={`collection-img-wrapper ${filter ? 'myfilter' : ''}`}>
                <img src={image} alt={alt} />
              </div>
            ) : null}
            {vimeoVideo ? (
              <div className="vimeo-video-wrapper collection-vimeo">
                <iframe
                  title={title}
                  // eslint-disable-next-line max-len
                  src={`https://player.vimeo.com/video/${videoId}?speed=0&autoplay=1&controls=0&background=1&quality=720p&loop=1&muted=1}`}
                  width="640"
                  height="360"
                  frameBorder="0"
                  webkitallowfullscreen
                  mozallowfullscreen
                  allowFullScreen
                ></iframe>
              </div>
            ) : null}

            {text && imgUrl ? (
              <div className="collection-text small-collection-text" dangerouslySetInnerHTML={{__html: text}}></div>
            ) : null}
          </div>
        </a>
      ) : (
        <div className={text ? 'card card-small-grid' : 'card-nocontent'}>
          {title ? (
            <div className="itemtext">
              <h4>{title}</h4>
              {text && !imgUrl ? (
                <div className="collection-text" dangerouslySetInnerHTML={{__html: text}}></div>
              ) : null}
            </div>
          ) : null}
          {image ? (
            <div className={`collection-img-wrapper ${filter ? 'myfilter' : ''}`}>
              <img src={image} alt={alt} />
            </div>
          ) : null}
          {vimeoVideo ? (
            <div className="vimeo-video-wrapper collection-vimeo">
              <iframe
                title={title}
                // eslint-disable-next-line max-len
                src={`https://player.vimeo.com/video/${videoId}?speed=0&autoplay=1&controls=0&background=1&quality=720p&loop=1&muted=1}`}
                width="640"
                height="360"
                frameBorder="0"
                webkitallowfullscreen
                mozallowfullscreen
                allowFullScreen
              ></iframe>
            </div>
          ) : null}
          {text && imgUrl ? (
            <div className="collection-text small-collection-text" dangerouslySetInnerHTML={{__html: text}}></div>
          ) : null}
        </div>
      )}
    </div>
  );
};

export default function CollectionGridSmall(slice) {
  const bgColor = slice.primary.background_variant === 'bg-color' ? true : false;
  const carousel = slice.primary.mobil_karusell === true ? true : false;

  return (
    <>
      <section className={`grid ${carousel ? 'hidden-by-carousel ' : ' '} ${bgColor ? 'bg-section' : ''}`}>
        {getAlternatingRows(slice)}
      </section>
      <section
        className={`grid carousel-section ${carousel ? 'carousel-active ' : ' '} ${bgColor ? 'bg-section' : ''}`}
      >
        <Carousel>{getCarouselRows(slice)}</Carousel>
      </section>
    </>
  );
}

const getAlternatingRows = (slice) => {
  const gridHtml = [];

  for (let i = 0; i < slice.items.length; i += 3) {
    if (slice.items[i + 2]) {
      gridHtml.push(
          <div key={i} className="card-row small-grid-row">
            {drawItem(slice.items[i])}
            {drawItem(slice.items[i + 1])}
            {drawItem(slice.items[i + 2])}
          </div>,
      );
      continue;
    }
    if (slice.items[i + 1]) {
      gridHtml.push(
          <div key={i} className="card-row small-grid-row">
            {drawItem(slice.items[i])}
            {drawItem(slice.items[i + 1])}
          </div>,
      );
    } else {
      gridHtml.push(
          <div key={i} className="card-row small-grid-row">
            {drawItem(slice.items[i])}
          </div>,
      );
    }
  }
  return gridHtml;
};

const getCarouselRows = (slice) => {
  const gridHtml = [];

  for (let i = 0; i < slice.items.length; i++) {
    gridHtml.push(
        <div key={i} className="card-row small-grid-row">
          {drawItem(slice.items[i])}
        </div>,
    );
  }
  return gridHtml;
};
