import PropTypes from 'prop-types';
import React from 'react';

export default function ActionCallFullWidth2Columns(sliceInfo) {
  const img = sliceInfo.primary?.bakgrundsbild?.url;
  const title = sliceInfo.primary?.title?.text;
  const description = sliceInfo.primary?.text.html;

  return (
    <section key={sliceInfo.uid} className={`section-full-width section-px py-20 sm:py-10`}>
      <div className="flex flex-wrap">
        <div className="md:w-3/5 w-full">
          <div className={`flex items-center justify-center h-550 sm:h-444`}>
            <img src={img} className="object-contain" alt="" />
          </div>
        </div>
        <div className="md:w-2/5 w-full flex flex-col justify-center pl-10 sm:pl-0">
          <h2 className="text-3xl text-black font-semibold uppercase mt-0 mb-4">{title}</h2>
          <div
            className="text-base font-fira text-black mb-8 c2-content"
            dangerouslySetInnerHTML={{__html: description}}
          />
        </div>
      </div>
    </section>
  );
}

ActionCallFullWidth2Columns.propTypes = {
  sliceInfo: PropTypes.object.isRequired,
};
