import PropTypes from 'prop-types';
import React from 'react';

export default function CollectionFullWidth2Columns(sliceInfo) {
  const bgVariant = sliceInfo.primary?.background_variant;
  const items = sliceInfo.items;

  return (
    <section key={sliceInfo.uid} className={`section-full-width section-px bg-${bgVariant} py-20 sm:py-10`}>
      {items &&
        items.map((item, index) => (
          <div key={`2col-${index}`} className="flex flex-wrap mb-20 sm:mb-10 last:mb-0">
            <GridItem item={item} index={index} />
          </div>
        ))}
    </section>
  );
}

CollectionFullWidth2Columns.propTypes = {
  sliceInfo: PropTypes.object.isRequired,
};

const GridItem = ({item, index}) => {
  return (
    <>
      {index % 2 == 0 ? (
        <>
          <div className="w-1/2 sm:w-full">
            <div
              key={index}
              className={`bg-cover bg-no-repeat bg-center h-870 pr-10 sm:h-444 filter-${item.filter_select} ` +
              `${item.filter && 'myfilter'}`}
              style={{backgroundImage: `url('${item.collection_image?.url}')`}}
            ></div>
          </div>
          <div className="w-1/2 sm:w-full flex flex-col justify-center pl-10 sm:pl-0 pt-10 md:pt-0">
            <h2 className="text-3xl text-black font-semibold uppercase mt-0 mb-4">{item.collection_title}</h2>
            <div
              className="text-base font-fira text-black mb-0 c2-content"
              dangerouslySetInnerHTML={{__html: item.collection_description?.html}}
            />
          </div>
        </>
      ) : (
        <>
          <div className="w-1/2 sm:w-full md:hidden">
            <div
              key={index}
              className={`bg-cover bg-no-repeat bg-center h-870 sm:h-444 sm:mb-5 filter-${item.filter_select}`}
              style={{backgroundImage: `url('${item.collection_image?.url}')`}}
            ></div>
          </div>
          <div className="w-1/2 sm:w-full flex flex-col justify-center pr-10 sm:pr-0">
            <h2 className="text-3xl text-black font-semibold uppercase mt-0 mb-5 sm:mb-4">{item.collection_title}</h2>
            <div
              className="text-base font-fira text-black mb-0 c2-content"
              dangerouslySetInnerHTML={{__html: item.collection_description?.html}}
            />
          </div>
          <div className="w-1/2 sm:w-full sm:hidden">
            <div
              key={index}
              className={`bg-cover bg-no-repeat bg-center h-870 pl-10 filter-${item.filter_select}`}
              style={{backgroundImage: `url('${item.collection_image?.url}')`}}
            ></div>
          </div>
        </>
      )}
    </>
  );
};

GridItem.propTypes = {
  item: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
};
