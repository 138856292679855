import PropTypes from 'prop-types';
import React from 'react';

import arrow from '../../images/arrow-right-black.svg';
import LinkButton from '../LinkButton';

const FullWidthDatesGrid = ({slice}) => {
  const bgVariant = slice.primary?.fwd_background_variant;
  const buttonVariant = slice.primary?.fwd_button_variant;
  const header = slice.primary?.fwd_header?.text;
  const subheader = slice.primary?.fwd_text?.text;
  const localLink = slice.primary?.fwd_lank?.uid;
  const externalLink = slice.primary?.fwd_lank?.url;
  const linkLang = slice.primary?.fwd_lank?.lang;
  const callToAction = slice.primary?.fwd_call_to_action?.text;
  const items = slice.items;

  return (
    <section key={slice.uid} className={`section-full-width bg-${bgVariant} py-20 sm:py-10`}>
      {header && (
        <div className="w-full mb-10">
          <h2 className="text-black text-3xl md:text-4xl font-semibold uppercase">{header}</h2>
          <p className="text-black font-fira text-base mb-0">{subheader}</p>
        </div>
      )}
      <div className="flex w-full space-x-8 sm:flex-wrap sm:space-x-0">
        {items &&
          items.map((item, index) => (
            <div key={`fwd-item-${index}`} className="w-1/3 sm:w-full sm:mb-8 sm:flex-wrap sm:flex">
              <GridItem item={item} index={index} />
            </div>
          ))}
      </div>
      <div className="flex justify-center my-10">
        {buttonVariant === 'green' ? (
          <LinkButton localLink={localLink} externalLink={externalLink} linkLang={linkLang}>
            <button className="mx-auto px-10 hover:bg-green-base hover:text-white">{callToAction}</button>
          </LinkButton>
        ) : (
          <LinkButton localLink={localLink} externalLink={externalLink} linkLang={linkLang}>
            <button
              className={
                `bg-transparent hover:bg-transparent hover:text-black p-0 ` +
                `uppercase font-rift text-black text-base font-semibold flex`
              }
            >
              {callToAction}
              <img className="ml-2" src={arrow} alt="" />
            </button>
          </LinkButton>
        )}
      </div>
    </section>
  );
};

FullWidthDatesGrid.propTypes = {
  slice: PropTypes.object.isRequired,
};

const GridItem = ({item, index}) => {
  const startDate = new Date(item.fwd_start_date);
  const startEnd = new Date(item.fwd_end_date);
  const startDay = startDate.toLocaleString('sv-se', {day: 'numeric'});
  const startMonth = startDate.toLocaleString('sv-se', {month: 'long'});
  const endDay = startEnd.toLocaleString('sv-se', {day: 'numeric'});
  const endMonth = startEnd.toLocaleString('sv-se', {month: 'long'});
  return (
    <>
      <div className="overflow-hidden group">
        <div
          key={index}
          className="bg-cover bg-no-repeat bg-center h-444 sm:h-56 relative sm:w-1/2 ` +
          `transition-all duration-500 hover:scale-105"
          style={{backgroundImage: `url('${item.fwd_bild?.url}')`}}
        ></div>
      </div>
      <div className="py-3 sm:w-1/2 sm:pl-5 group">
        <LinkButton localLink={item.fwd_lank?.uid} externalLink={item.fwd_lank?.url} linkLang={item.fwd_lank?.lang}>
          <span className="text-sm block font-rift font-semibold uppercase date-day mb-1 md:text-base">
            {startDay} {startMonth} - {endDay} {endMonth}
          </span>
          <h2 className="text-3xl text-black font-semibold uppercase mt-0 mb-1">{item.fwd_titel.text}</h2>
          <p className="text-black font-fira text-base mb-1">{item.fwd_text.text}</p>
          <button
            className={
              `bg-transparent hover:bg-transparent hover:text-black p-0 ` +
              `uppercase font-rift text-black text-base font-semibold flex`
            }
          >
            {item.fwd_call_to_action.text}
            <img className="ml-2 group-hover:ml-4 transition-all duration-200" src={arrow} alt="" />
          </button>
        </LinkButton>
      </div>
    </>
  );
};

GridItem.propTypes = {
  item: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
};

export default FullWidthDatesGrid;
