import PropTypes from 'prop-types';
import React from 'react';

import {getLanguageFromUrl} from '../../../utils/getLanguageFromUrl';
import {getReadMoreString} from '../../../utils/getReadMoreString';
import arrow from '../../../images/arrow-right-black.svg';
import arrowRight from '../../../images/arrow-right.png';
import LinkButton from '../../LinkButton';

export default function FullWidth4Items(sliceInfo) {
  const bgVariant = sliceInfo.primary?.background_variant;
  const buttonVariant = sliceInfo.primary?.button_variant;
  const header = sliceInfo.primary?.full_width_header?.text;
  const subheader = sliceInfo.primary?.full_width_text?.text;
  const callToAction = sliceInfo.primary?.cta?.text;
  const localLink = sliceInfo.primary?.lank?.uid;
  const externalLink = sliceInfo.primary?.lank?.raw?.url;
  const linkLang = sliceInfo.primary?.lank?.lang;
  const items = sliceInfo.items;

  return (
    <section key={sliceInfo.uid} className={`section-full-width bg-${bgVariant} py-20 sm:py-10 mobile-nopadding`}>
      {header && (
        <div className="w-full mb-10 sm:px-8">
          <h2 className="text-black text-3xl md:text-4xl font-semibold uppercase mt-0">{header}</h2>
          <p className="text-black font-fira text-base mb-0">{subheader}</p>
        </div>
      )}
      <div className="flex w-full space-x-8 sm:px-3 sm:flex-wrap sm:space-x-0">
        {items &&
          items.map((item, index) => (
            <div key={`4item-${index}`} className={`w-1/4 sm:w-1/2 ${index % 2 == 0 ? 'sm:pr-2' : 'sm:pl-2'} sm:mb-8`}>
              <GridItem item={item} index={index} />
            </div>
          ))}
      </div>
      <div className="flex justify-center my-0 md:my-10">
        {buttonVariant === 'green' ? (
          <LinkButton localLink={localLink} externalLink={externalLink} linkLang={linkLang}>
            <button className="mx-auto px-10 hover:bg-green-base hover:text-white">{callToAction}</button>
          </LinkButton>
        ) : (
          <LinkButton localLink={localLink} externalLink={externalLink} linkLang={linkLang}>
            <button
              className={
                `bg-transparent hover:bg-transparent hover:text-black p-0 uppercase ` +
                `font-rift text-black text-base font-semibold flex`
              }
            >
              {callToAction}
              <img className="ml-2" src={arrow} alt="" />
            </button>
          </LinkButton>
        )}
      </div>
    </section>
  );
}

FullWidth4Items.propTypes = {
  sliceInfo: PropTypes.object.isRequired,
};

const GridItem = ({item, index}) => {
  const language = getLanguageFromUrl();
  const size = item.image_size;
  return (
    <div key={index}>
      <LinkButton
        localLink={item.lank?.uid}
        externalLink={item.lank?.raw?.url}
        linkLang={item.lank?.lang}
      >
        <div className='relative group overflow-hidden hidden md:block'>
          <div
            className={`bg-cover bg-no-repeat bg-center relative transition-all duration-500 hover:scale-105 ${
              size === 'short' ?
                'h-186 md:h-444' :
                'h-222 md:h-444' + ` ${item.filter_select ? 'filter-' + item.filter_select : ''}`
            } ${item.filter ? 'filter-' + item.filter : ''} `}
            style={{backgroundImage: `url('${item.bild?.url}')`}}
          ></div>
          {item.hover ? (
            <>
              <div
                className={`absolute bottom-5 md:left-5 left-8 ` +
                `group-hover:opacity-0 transition-all duration-100 ease-in`}
              >
                <h2 className="text-3xl text-white font-semibold uppercase mb-0">{item.titel.text}</h2>
                <p className='text-white text-base uppercase font-rift text-underline md:hidden mb-0'>
                  {item?.show_more_text?.text ? item.show_more_text.text : 'Visa mer'}
                </p>
              </div>
              <div
                className={`absolute opacity-0 group-hover:opacity-100 -bottom-40 left-0 right-0 ` +
                `group-hover:bottom-0 transition-all duration-300 ease-in`}>
                <div className='p-5 bg-black bg-opacity-50 transition-all duration-300 ease-in'>
                  <h2 className="text-3xl text-white font-semibold uppercase mb-0 mt-0">{item.titel.text}</h2>
                  <p className={`text-white mb-2 text-base mt-2`}>
                    {item.text.text}
                  </p>
                  <div className='flex'>
                    <span className='text-white text-base uppercase font-rift'>
                      {item?.button_text?.text ? item.button_text.text : getReadMoreString(language)}
                    </span>
                    <img className="ml-2" src={arrowRight} alt="" />
                  </div>
                </div>
              </div>
            </>
          ) : (
            <div className="bg-gray px-3 py-2 md:py-5">
              <h2 className="text-2xl sm:text-lg text-black font-semibold uppercase mt-0 mb-1">{item.titel.text}</h2>
              <p className="text-base font-rift uppercase text-black mb-0">{item.text.text}</p>
            </div>
          )}
        </div>
      </LinkButton>
      <div className="block md:hidden">
        <div className='relative group overflow-hidden'>
          <div
            className={`bg-cover bg-no-repeat bg-center relative transition-all duration-500 hover:scale-105 ${
              size === 'short' ?
                'h-[286px] md:h-444' :
                'h-[322px] md:h-444' + ` ${item.filter_select ? 'filter-' + item.filter_select : ''}`
            } `}
            style={{backgroundImage: `url('${item.bild?.url}')`}}
          ></div>
          {item.hover ? (
            <>
              {item.text.text ? (
                <>
                  <div
                    className={`absolute bottom-4 md:left-8 left-4 ` +
                    `group-hover:opacity-0 transition-all duration-100 ease-in`}
                  >
                    <h2 className="text-3xl sm:text-lg text-white font-semibold uppercase mb-0">{item.titel.text}</h2>
                    <p className='text-white text-base uppercase font-rift text-underline md:hidden mb-0'>
                      {item?.show_more_text?.text ? item.show_more_text.text : 'Visa mer'}
                    </p>
                  </div>
                  <div
                    className={`absolute opacity-0 group-hover:opacity-100 -bottom-40 left-0 right-0 ` +
                    `group-hover:bottom-0 transition-all duration-300 ease-in`}>
                    <div className='p-5 bg-black bg-opacity-50 transition-all duration-300 ease-in'>
                      <h2 className="text-3xl sm:text-lg text-white font-semibold uppercase mb-0 mt-0">
                        {item.titel.text}
                      </h2>
                      <p className={`text-white mb-2 text-sm md:text-base mt-2`}>
                        {item.text.text}
                      </p>
                      <LinkButton
                        localLink={item.lank?.uid}
                        externalLink={item.lank?.raw?.url}
                        linkLang={item.lank?.lang}
                      >
                        <div className='flex'>
                          <span className='text-white text-base uppercase font-rift'>
                            {item?.button_text?.text ? item.button_text.text : getReadMoreString(language)}
                          </span>
                          <img className="ml-2" src={arrowRight} alt="" />
                        </div>
                      </LinkButton>
                    </div>
                  </div>
                </>
              ) : (
                <div className="absolute bottom-4 md:left-8 left-4">
                  <LinkButton
                    localLink={item.lank?.uid}
                    externalLink={item.lank?.raw?.url}
                    linkLang={item.lank?.lang}
                  >
                    <h2 className="text-3xl sm:text-lg text-white font-semibold uppercase mb-0">
                      {item.titel.text}
                    </h2>
                  </LinkButton>
                </div>
              )}
            </>
          ) : (
            <div className="bg-gray px-3 py-2 md:py-5">
              <LinkButton
                localLink={item.lank?.uid}
                externalLink={item.lank?.raw?.url}
                linkLang={item.lank?.lang}
              >
                <h2 className="text-2xl sm:text-lg text-black font-semibold uppercase mt-0 mb-1">{item.titel.text}</h2>
                <p className="text-base font-rift uppercase text-black mb-0">{item.text.text}</p>
              </LinkButton>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

GridItem.propTypes = {
  item: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
};
