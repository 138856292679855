import PropTypes from 'prop-types';
import React from 'react';

import {getLanguageFromUrl} from '../../../utils/getLanguageFromUrl';
import {getReadMoreString} from '../../../utils/getReadMoreString';
import arrowRight from '../../../images/arrow-right.png';
import LinkButton from '../../LinkButton';

export default function CollectionFullWidth2Items(sliceInfo) {
  const bgVariant = sliceInfo.primary?.background_variant;
  const header = sliceInfo.primary?.collection_main_title?.text;
  const subheader = sliceInfo.primary?.ingress?.text;
  const items = sliceInfo.items;

  return (
    <section key={sliceInfo.uid} className={`section-full-width bg-${bgVariant} py-20 sm:py-10 mobile-nopadding`}>
      {header && (
        <div className="w-full sm:mb-10 mb-20 sm:px-8">
          <p className="text-black font-rift text-xl mb-1 uppercase">{subheader}</p>
          <h2 className="text-black text-3xl md:text-4xl font-semibold uppercase mt-0">{header}</h2>
        </div>
      )}
      <div className="flex w-full sm:px-8 flex-wrap">
        {items &&
          items.map((item, index) => (
            <div
              key={`2item-${index}`}
              className={`w-1/2 sm:w-full ${index % 2 == 0 ? 'pr-5 sm:pr-0' : 'pl-5 sm:pl-0'} sm:mb-5 mb-20`}
            >
              <GridItem item={item} index={index} />
            </div>
          ))}
      </div>
    </section>
  );
}

CollectionFullWidth2Items.propTypes = {
  sliceInfo: PropTypes.object.isRequired,
};

const GridItem = ({item, index}) => {
  const language = getLanguageFromUrl();
  const size = item.image_size;
  return (
    <div key={index}>
      <LinkButton
        localLink={item.collection_link?.uid}
        externalLink={item.collection_link?.raw?.url}
        linkLang={item.collection_link?.lang}
      >
        <div className="overflow-hidden relative group hidden md:block">
          <div
            className={`bg-cover bg-no-repeat bg-center sm:bg-top transition-all duration-500 hover:scale-105 ${
              size === 'short' ? 'h-940 sm:h-335' : 'h-1100 sm:h-470'
            } filter-${item.filter_select} ${item.filter && 'myfilter'}`}
            style={{backgroundImage: `url('${item.collection_image?.url}')`}}
          ></div>
          {item.hover ? (
            <>
              <div
                className={`absolute bottom-5 md:left-5 left-8 ` +
                `group-hover:opacity-0 transition-all duration-100 ease-in`}
              >
                <h2 className="text-3xl text-white font-semibold uppercase mb-0">{item.collection_title}</h2>
                <p className='text-white text-base uppercase font-rift text-underline md:hidden mb-0'>
                  {item?.show_more_text?.text ? item.show_more_text.text : 'Visa mer'}
                </p>
              </div>
              <div
                className={`absolute opacity-0 group-hover:opacity-100 -bottom-40 left-0 right-0 ` +
                `group-hover:bottom-0 transition-all duration-300 ease-in`}>
                <div className='p-5 bg-black bg-opacity-50 transition-all duration-300 ease-in'>
                  <h2 className="text-3xl text-white font-semibold uppercase mb-0 mt-0">{item.collection_title}</h2>
                  <p className={`text-white mb-2 text-base mt-2`}>
                    {item.collection_description.text}
                  </p>
                  <div className='flex'>
                    <span className='text-white text-base uppercase font-rift'>
                      {item?.button_text?.text ? item.button_text.text : getReadMoreString(language)}
                    </span>
                    <img className="ml-2" src={arrowRight} alt="" />
                  </div>
                </div>
              </div>
            </>
          ) : (
            <div className="bg-gray px-3 py-2 md:py-5">
              <h2 className="text-2xl sm:text-lg text-black font-semibold uppercase mt-0 mb-1">
                {item.collection_title}
              </h2>
              <p className="text-base font-rift uppercase text-black mb-0">{item.collection_description.text}</p>
            </div>
          )}
        </div>
      </LinkButton>
      <div className="block md:hidden">
        <div className='relative group overflow-hidden'>
          <div
            className={`bg-cover bg-no-repeat bg-center sm:bg-top transition-all duration-500 hover:scale-105 ${
              size === 'short' ? 'h-940 sm:h-335' : 'h-1100 sm:h-470'
            } filter-${item.filter_select}`}
            style={{backgroundImage: `url('${item.collection_image?.url}')`}}
          ></div>
          {item.hover ? (
            <>
              {item.collection_description.text ? (
                <>
                  <div
                    className={`absolute bottom-4 md:left-8 left-4 ` +
                    `group-hover:opacity-0 transition-all duration-100 ease-in`}
                  >
                    <h2 className="text-3xl sm:text-lg text-white font-semibold uppercase mb-0">
                      {item.collection_title}
                    </h2>
                    <p className='text-white text-base uppercase font-rift text-underline md:hidden mb-0'>
                      {item?.show_more_text?.text ? item.show_more_text.text : 'Visa mer'}
                    </p>
                  </div>
                  <div
                    className={`absolute opacity-0 group-hover:opacity-100 -bottom-40 left-0 right-0 ` +
                    `group-hover:bottom-0 transition-all duration-300 ease-in`}>
                    <div className='p-5 bg-black bg-opacity-50 transition-all duration-300 ease-in'>
                      <h2 className="text-3xl sm:text-lg text-white font-semibold uppercase mb-0 mt-0">
                        {item.collection_title}
                      </h2>
                      <p className={`text-white mb-2 text-sm md:text-base mt-2`}>
                        {item.collection_description.text}
                      </p>
                      <LinkButton
                        localLink={item.collection_link?.uid}
                        externalLink={item.collection_link?.raw?.url}
                        linkLang={item.collection_link?.lang}
                      >
                        <div className='flex'>
                          <span className='text-white text-base uppercase font-rift'>
                            {item?.button_text?.text ? item.button_text.text : getReadMoreString(language)}
                          </span>
                          <img className="ml-2" src={arrowRight} alt="" />
                        </div>
                      </LinkButton>
                    </div>
                  </div>
                </>
              ) : (
                <div className="absolute bottom-4 md:left-8 left-4">
                  <LinkButton
                    localLink={item.collection_link?.uid}
                    externalLink={item.collection_link?.raw?.url}
                    linkLang={item.collection_link?.lang}
                  >
                    <h2 className="text-3xl sm:text-lg text-white font-semibold uppercase mb-0">
                      {item.collection_title}
                    </h2>
                  </LinkButton>
                </div>
              )}
            </>
          ) : (
            <div className="bg-gray px-3 py-2 md:py-5">
              <LinkButton
                localLink={item.collection_link?.uid}
                externalLink={item.collection_link?.raw?.url}
                linkLang={item.collection_link?.lang}
              >
                <h2 className="text-2xl sm:text-lg text-black font-semibold uppercase mt-0 mb-1">
                  {item.collection_title}
                </h2>
                <p className="text-base font-rift uppercase text-black mb-0">{item.collection_description.text}</p>
              </LinkButton>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

GridItem.propTypes = {
  item: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
};
