import {graphql, useStaticQuery} from 'gatsby';
import {useMergePrismicPreviewData} from 'gatsby-plugin-prismic-previews';
import React from 'react';
import PropTypes from 'prop-types';

import {HeroFragment} from '../../fragments';
import {createLangShortcut} from '../../utils/createLangShortcut';
import useWindowDimensions from '../../utils/useWindowDimensions';

const FlerArtiklar = ({slice, temaUid}) => {
  const staticData = useStaticQuery(graphql`
    query {
      allPrismicContent {
        edges {
          node {
            uid
            lang
            _previewable
            data {
              body {
                ...HeroFragment
              }
              taggar {
                text
              }
            }
          }
        }
      }
    }
  `);

  // eslint-disable-next-line no-unused-vars
  const {data, isPreview} = useMergePrismicPreviewData(staticData);

  const [temaArr, setTemaArr] = React.useState([]);
  React.useEffect(() => {
    let arr = data.allPrismicContent.edges;
    arr = arr.filter(
        (post) => post.node.lang === 'sv-se' && post.node.data.taggar?.text.toLowerCase().includes(checkName(temaUid)),
    );
    setTemaArr(arr);
  }, [data.allPrismicContent.edges, temaUid]);

  const {width} = useWindowDimensions();

  const checkName = (name) => {
    return name.replace(/-/g, ' ');
  };

  return (
    <section className="temaCollection flera-artiklar">
      <h5 className="temarelatedh4">{slice.primary.rubrik.text}</h5>
      <div className="list-small mobile-small-grid">{getAlternatingRows(temaArr, width)}</div>
    </section>
  );
};

FlerArtiklar.propTypes = {
  slice: PropTypes.object,
  temaUid: PropTypes.object,
};

export default FlerArtiklar;

function getAlternatingRows(temaArr, width) {
  const gridHtml = [];
  let temparr = [];

  if (width > 900) {
    for (let i = 0; i < temaArr.length; i++) {
      temparr.push(temaArr[i]);
      if (i % 3 === 2 || i === temaArr.length - 1) {
        gridHtml.push(drawRow(temparr));
        temparr = [];
      }
    }
  } else {
    for (let i = 0; i < temaArr.length; i++) {
      temparr.push(temaArr[i]);
      gridHtml.push(drawRow(temparr));
      temparr = [];
    }
  }
  return gridHtml;
}

function drawRow(anArr, keyName) {
  return (
    <div key={keyName} className="card-row fler-card-row">
      {anArr.map((item) => drawItem(item))}
    </div>
  );
}

const drawItem = (item) => {
  const {primary} = item.node.data.body[0];
  const image = primary.hero_image.fluid?.src;
  const alt = primary.hero_image.alt;
  const title = primary.hero_title.text;
  const localLink = item?.node.uid;
  const linkLang = item?.node.lang;
  return (
    <div className="cardWrapper" key={item.collection_title}>
      <a href={'/' + createLangShortcut(linkLang) + '/' + localLink}>
        <div className="card-nocontent">
          {title ? <h4>{title}</h4> : null}
          {image ? <img src={image} alt={alt} /> : null}
        </div>
      </a>
    </div>
  );
};

FlerArtiklar.fragments = [HeroFragment];
