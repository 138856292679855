/* eslint-disable new-cap */
import {graphql, useStaticQuery} from 'gatsby';
import {useMergePrismicPreviewData} from 'gatsby-plugin-prismic-previews';
import PropTypes from 'prop-types';

import {HeroFragment} from '../../fragments';
import {getLinkedArticle} from '../../utils/getLinkedArticle';
import FeatureCallToAction from './Feature/FeatureCallToAction';
import FeatureFullWidth from './Feature/FeatureFullWidth';
import FeatureFullWidthImage from './Feature/FeatureFullWidthImage';
import FeatureSplitColorTight from './Feature/FeatureSplitColorThight';

const Feature = ({slice}) => {
  const staticData = useStaticQuery(graphql`
    query {
      allPrismicContent {
        edges {
          node {
            uid
            _previewable
            data {
              body {
                ...HeroFragment
              }
            }
          }
        }
      }
    }
  `);
  const {primary} = slice;

  // eslint-disable-next-line no-unused-vars
  const {data, isPreview} = useMergePrismicPreviewData(staticData);

  slice = getLinkedArticle(slice, data);

  if (!primary) {
    return null;
  }

  const variant = primary.variant.toLowerCase();
  switch (variant) {
    case 'call to action':
      return FeatureCallToAction(slice);
    case 'split':
      return FeatureSplitColorTight(slice);
    case 'full width image':
      return FeatureFullWidthImage(slice);
    default:
      return FeatureFullWidth(slice);
  }
};

Feature.fragments = [HeroFragment];

Feature.propTypes = {
  slice: PropTypes.object,
};

export default Feature;
