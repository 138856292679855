import {Link} from 'gatsby';
import * as React from 'react';

import {createLangShortcut} from '../../../utils/createLangShortcut';
import {cutText} from '../../../utils/cutText';
import Carousel from '../../Carousel';
import './grid_large3.css';
import './slice_collection_small.css';

const drawItem = (item) => {
  const linkedHero = item.correctSlice?.primary;
  const image = item.collection_image?.fluid?.src || linkedHero?.collection_image?.fluid?.src;
  const alt = item.collection_image?.alt;
  const title = item.collection_title || linkedHero?.hero_title.text;
  const text = item?.collection_description[0]?.text || item.collection_description.text;
  const localLink = item?.collection_link.uid;
  const externalLink = item.collection_link.raw.url;
  const linkLang = item.collection_link.lang;
  const filter = item.filter;
  const filterSelect = item?.filter_select;
  const vimeoVideo = item?.vimeo_video?.embed_url;
  let segments = [];
  let videoId = '';
  if (vimeoVideo) {
    segments = vimeoVideo.split('/');
    videoId = segments[segments.length - 1];
  }

  return (
    <div className="cardWrapperTall items-3" key={item.collection_title}>
      {localLink ? (
        <Link to={'/' + createLangShortcut(linkLang) + '/' + localLink}>
          <div className={text ? 'card' : 'card-nocontent'}>
            <div className="title-wrapper">
              {title ? <h4 className="font-medium">{title}</h4> : null}
              {image ? (
                <div className={`collection-img-wrapper ${filter ? 'myfilter' : ''} ` +
                `${filterSelect ? 'filter-' + filterSelect : ''}`}>
                  <img src={image} alt={alt} />
                </div>
              ) : null}
              {vimeoVideo ? (
                <div className="vimeo-video-wrapper collection-vimeo">
                  <iframe
                    title={title}
                    // eslint-disable-next-line max-len
                    src={`https://player.vimeo.com/video/${videoId}?speed=0&autoplay=1&controls=0&background=1&quality=720p&loop=1&muted=1}`}
                    width="640"
                    height="360"
                    frameBorder="0"
                    webkitallowfullscreen
                    mozallowfullscreen
                    allowFullScreen
                  ></iframe>
                </div>
              ) : null}
            </div>
            {text ? <p>{text ? cutText(text, 250) : null}</p> : null}
          </div>
        </Link>
      ) : externalLink ? (
        <a href={externalLink} target="_blank" rel="noreferrer">
          <div className={text ? 'card' : 'card-nocontent'}>
            <div className="title-wrapper">
              {title ? <h4 className="font-medium">{title}</h4> : null}
              {image ? (
                <div className={`collection-img-wrapper ${filter ? 'myfilter' : ''}`}>
                  <img src={image} alt={alt} />
                </div>
              ) : null}
              {vimeoVideo ? (
                <div className="vimeo-video-wrapper collection-vimeo">
                  <iframe
                    title={title}
                    // eslint-disable-next-line max-len
                    src={`https://player.vimeo.com/video/${videoId}?speed=0&autoplay=1&controls=0&background=1&quality=720p&loop=1&muted=1}`}
                    width="640"
                    height="360"
                    frameBorder="0"
                    webkitallowfullscreen
                    mozallowfullscreen
                    allowFullScreen
                  ></iframe>
                </div>
              ) : null}
            </div>
            {text ? <p>{text ? cutText(text, 250) : null}</p> : null}
          </div>
        </a>
      ) : (
        <div className={text ? 'card' : 'card-nocontent'}>
          <div className="title-wrapper">
            {title ? <h4 className="font-medium">{title}</h4> : null}
            {image ? (
              <div className={`collection-img-wrapper ${filter ? 'myfilter' : ''}`}>
                <img src={image} alt={alt} />
              </div>
            ) : null}
            {vimeoVideo ? (
              <div className="vimeo-video-wrapper collection-vimeo">
                <iframe
                  title={title}
                  // eslint-disable-next-line max-len
                  src={`https://player.vimeo.com/video/${videoId}?speed=0&autoplay=1&controls=0&background=1&quality=720p&loop=1&muted=1}`}
                  width="640"
                  height="360"
                  frameBorder="0"
                  webkitallowfullscreen
                  mozallowfullscreen
                  allowFullScreen
                ></iframe>
              </div>
            ) : null}
          </div>
          {text ? <p>{text ? cutText(text, 250) : null}</p> : null}
        </div>
      )}
    </div>
  );
};

export default function CollectionGridLarge3PerRow(slice) {
  const bgColor = slice.primary.background_variant === 'bg-color' ? true : false;
  const carousel = slice.primary.mobil_karusell === true ? true : false;
  const collectiontitle = slice?.primary?.collection_main_title?.text;
  const collectioningress = slice?.primary?.ingress?.text;

  return (
    <>
      <section
        className={`${carousel ? 'hidden-by-carousel ' : ' '} ${
          bgColor ?
            slice?.source ?
              'bg-section temaCollection large-grid-section' :
              'bg-section contentCollection large-grid-section' :
            slice?.source ?
            'temaCollection large-grid-section' :
            'contentCollection large-grid-section'
        }`}
        key={Math.random()}
      >
        <div className="destination-grid-heading">
          {collectiontitle ? <h2 className="font-medium">{collectiontitle}</h2> : null}
          {collectioningress ? <p className="mx-auto">{collectioningress}</p> : null}
        </div>
        {getAlternatingRows(slice)}
      </section>
      <section
        className={`${carousel ? 'carousel-active ' : ' '} ${
          bgColor ?
            slice?.source ?
              'bg-section temaCollection large-grid-section carousel-section' :
              'bg-section contentCollection large-grid-section carousel-section' :
            slice?.source ?
            'temaCollection large-grid-section carousel-section' :
            'contentCollection large-grid-section carousel-section'
        }`}
        key={Math.random()}
      >
        <div className="destination-grid-heading-mobile">
          {collectiontitle ? <h2 className="font-medium">{collectiontitle}</h2> : null}
          {collectioningress ? <p className="mx-auto">{collectioningress}</p> : null}
        </div>
        <Carousel>{getCarouselRows(slice)}</Carousel>
      </section>
    </>
  );
}

const getAlternatingRows = (slice) => {
  const gridHtml = [];
  for (let i = 0; i < slice.items.length; i += 3) {
    if (slice.items[i + 2]) {
      gridHtml.push(
          <div key={i} className="card-row card-row-multiple">
            {drawItem(slice.items[i])}
            {drawItem(slice.items[i + 1])}
            {drawItem(slice.items[i + 2])}
          </div>,
      );
      continue;
    }
    if (slice.items[i + 1]) {
      gridHtml.push(
          <div key={i} className="card-row card-row-double">
            {drawItem(slice.items[i])}
            {drawItem(slice.items[i + 1])}
          </div>,
      );
    } else {
      gridHtml.push(
          <div key={i} className="card-row card-row-single">
            {drawItem(slice.items[i])}
          </div>,
      );
    }
  }

  return gridHtml;
};

const getCarouselRows = (slice) => {
  const gridHtml = [];

  for (let i = 0; i < slice.items.length; i++) {
    gridHtml.push(
        <div key={i} className="card-row">
          {drawItem(slice.items[i])}
        </div>,
    );
  }

  return gridHtml;
};
