import {Link} from 'gatsby';
import React from 'react';
import PropTypes from 'prop-types';

import {createLangShortcut} from '../../utils/createLangShortcut';
import imageSize from '../../utils/imageSize.js';
import './Collection/card_collection.css';
import './Collection/slice_collection_small.css';

const drawItem = (item) => {
  const image = item.bild?.fluid?.src;
  const alt = item.bild?.alt;
  const imgUrl = imageSize(image, 800);
  const title = item.titel?.text;
  const subtitle = item.subtitle?.text;
  const localLink = item?.lank?.uid;
  const externalLink = item.lank?.raw?.url;
  const linkLang = item.lank?.lang;
  const bgVariant = item?.background_variant;
  const vimeoVideo = item?.vimeo_video?.embed_url;
  let segments = [];
  let videoId = '';
  if (vimeoVideo) {
    segments = vimeoVideo.split('/');
    videoId = segments[segments.length - 1];
  }

  return (
    <div className={bgVariant + ' cardWrapper destination-grid'} key={item.collection_title}>
      {localLink ? (
        <Link to={'/' + createLangShortcut(linkLang) + '/' + localLink}>
          <div className={`card-destination-grid`}>
            {title ? (
              <div className="destination-text">
                <h4>{subtitle}</h4>
                <h3>{title}</h3>
              </div>
            ) : null}
            {imgUrl ? (
              <div className={`collection-img-wrapper`}>
                <img src={image} alt={alt} />
              </div>
            ) : null}
            {vimeoVideo ? (
              <div className="vimeo-video-wrapper collection-vimeo">
                <iframe
                  title={title}
                  // eslint-disable-next-line max-len
                  src={`https://player.vimeo.com/video/${videoId}?speed=0&autoplay=1&controls=0&background=1&quality=720p&loop=1&muted=1}`}
                  width="640"
                  height="360"
                  frameBorder="0"
                  webkitallowfullscreen
                  mozallowfullscreen
                  allowFullScreen
                ></iframe>
              </div>
            ) : null}
          </div>
        </Link>
      ) : externalLink ? (
        <a href={externalLink} target="_blank" rel="noreferrer">
          <div className={`card-destination-grid`}>
            {title ? (
              <div className="destination-text">
                <h4>{subtitle}</h4>
                <h3>{title}</h3>
              </div>
            ) : null}
            {imgUrl ? (
              <div className={`collection-img-wrapper`}>
                <img src={image} alt={alt} />
              </div>
            ) : null}
            {vimeoVideo ? (
              <div className="vimeo-video-wrapper collection-vimeo">
                <iframe
                  title={title}
                  // eslint-disable-next-line max-len
                  src={`https://player.vimeo.com/video/${videoId}?speed=0&autoplay=1&controls=0&background=1&quality=720p&loop=1&muted=1}`}
                  width="640"
                  height="360"
                  frameBorder="0"
                  webkitallowfullscreen
                  mozallowfullscreen
                  allowFullScreen
                ></iframe>
              </div>
            ) : null}
          </div>
        </a>
      ) : (
        <div className={`card-destination-grid`}>
          {title ? (
            <div className="destination-text">
              <h4>{subtitle}</h4>
              <h3>{title}</h3>
            </div>
          ) : null}
          {imgUrl ? (
            <div className={`collection-img-wrapper`}>
              <img src={image} alt={alt} />
            </div>
          ) : null}
          {vimeoVideo ? (
            <div className="vimeo-video-wrapper collection-vimeo">
              <iframe
                title={title}
                // eslint-disable-next-line max-len
                src={`https://player.vimeo.com/video/${videoId}?speed=0&autoplay=1&controls=0&background=1&quality=720p&loop=1&muted=1}`}
                width="640"
                height="360"
                frameBorder="0"
                webkitallowfullscreen
                mozallowfullscreen
                allowFullScreen
              ></iframe>
            </div>
          ) : null}
        </div>
      )}
    </div>
  );
};

const Destination = ({slice}) => {
  const title = slice.primary?.titel?.text;
  const text = slice.primary?.text?.text;
  const mobiletext = slice.primary?.text_mobile?.text;
  const fullWidthClass = slice.primary.destination_variant === 'full-width' ? 'section-full-width box-border' : '';

  return (
    <section
      className={`${slice?.source ? 'temaCollection ' : 'contentCollection'} ${fullWidthClass}`}
      key={Math.random()}
    >
      <div className="destination-grid-heading">
        <h2>{title}</h2>
        <p>{text}</p>
      </div>
      <div className="destination-grid-heading-mobile">
        <h2>{title}</h2>
        <p>{mobiletext}</p>
      </div>
      <div className="destination-grid-wrapper">{getAlternatingRows(slice)}</div>
    </section>
  );
};

Destination.propTypes = {
  slice: PropTypes.object,
};

export default Destination;

const getAlternatingRows = (slice) => {
  const gridHtml = [];

  for (let i = 0; i < slice.items.length; i += 2) {
    if (slice.items[i + 1]) {
      gridHtml.push(
          <div key={i} className="card-row">
            {drawItem(slice.items[i])}
            {drawItem(slice.items[i + 1])}
          </div>,
      );
    } else {
      gridHtml.push(
          <div key={i} className="card-row single-item">
            {drawItem(slice.items[i])}
          </div>,
      );
    }
  }

  return gridHtml;
};
