/* eslint-disable new-cap */
import PropTypes from 'prop-types';
import HeroFeatureFullWidth from './Hero/HeroFeatureFullWidth';
import HeroFeatureSplitLeft from './Hero/HeroFeatureSplitLeft';
import HeroFeatureSplitRight from './Hero/HeroFeatureSplitRight';
import HeroFullWidth from './Hero/HeroFullWidth';
import HeroSplit from './Hero/HeroSplit';

const Hero = ({slice}) => {
  const variant = slice?.primary?.hero_variant.toLowerCase();
  switch (variant) {
    case 'split':
      return HeroSplit(slice);
    case 'feature':
      return HeroFeatureFullWidth(slice);
    case 'feature split left':
      return HeroFeatureSplitLeft(slice);
    case 'feature split right':
      return HeroFeatureSplitRight(slice);
    default:
      return HeroFullWidth(slice);
  }
};

Hero.propTypes = {
  slice: PropTypes.object,
};

export default Hero;
