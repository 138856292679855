import React from 'react';
import PropTypes from 'prop-types';

import './divider.css';

const Divider = ({slice}) => {
  return (
    <section className={'content ' + slice?.source}>
      <div className="divider"></div>
    </section>
  );
};

Divider.propTypes = {
  slice: PropTypes.object,
};

export default Divider;
