import {Link} from 'gatsby';
import React from 'react';
import PropTypes from 'prop-types';

import {createLangShortcut} from '../../utils/createLangShortcut';
import {cutText} from '../../utils/cutText';
import {getLanguageFromUrl} from '../../utils/getLanguageFromUrl';
import {getReadMoreString} from '../../utils/getReadMoreString';
import imageSize from '../../utils/imageSize.js';
import './slice_quote.css';

const Quote = ({slice}) => {
  const data = slice.primary;
  const image = data.quote_image.fluid?.src;
  const alt = data.quote_image.alt;
  const imgUrl = imageSize(image, 800);
  const localLink = data?.quote_link.uid;
  const externalLink = data?.quote_link.url;
  const linkLang = data?.quote_link.lang;
  const line = slice?.primary?.line;
  const filter = slice?.primary?.filter;
  const language = getLanguageFromUrl();

  return (
    <section className={slice?.source ?? 'content'}>
      <div className="feature-split-color">
        <div className="feature-content">
          <div className="feature-content-inner">
            <h3 className="font-sm">{data.quote_text}</h3>
            <p>{cutText(data.quote_byline, 250)}</p>
            {line ? <div className="featunderline"></div> : null}
            {localLink ? (
              <Link to={'/' + createLangShortcut(linkLang) + '/' + data.quote_link.uid}>
                <button>{data?.quote_button_label || getReadMoreString(language)}</button>
              </Link>
            ) : externalLink ? (
              <a href={externalLink} target="_blank" rel="noreferrer">
                <button>{data?.quote_button_label || getReadMoreString(language)}</button>
              </a>
            ) : null}
            <div className="featunderline"></div>
          </div>
        </div>
        <div className={`feature-img ${filter === true ? 'myfilter' : ''}`}>
          {imgUrl ? <img className="quote-img" alt={alt} src={image}></img> : null}
        </div>
      </div>
    </section>
  );
};

Quote.propTypes = {
  slice: PropTypes.object,
};

export default Quote;
