export const getReadMoreString = (lang) => {
  if (lang === 'en') {
    return 'Read more';
  }

  if (lang === 'no') {
    return 'Les mer';
  }

  if (lang === 'de') {
    return 'Mehr lesen';
  }

  if (lang === 'da') {
    return 'Læs mere';
  }

  return 'Läs mer';
};
