import {Link} from 'gatsby';
import PropTypes from 'prop-types';
import React from 'react';

import android from '../../../images/android.png';
import ios from '../../../images/ios.png';
import {createLangShortcut} from '../../../utils/createLangShortcut';
import {cutText} from '../../../utils/cutText';
import {getLanguageFromUrl} from '../../../utils/getLanguageFromUrl';
import {getReadMoreString} from '../../../utils/getReadMoreString';
import imageSize from '../../../utils/imageSize.js';

export default function ActionCall(sliceInfo) {
  const imgUrl = imageSize(sliceInfo?.primary?.bakgrundsbild?.url, 1600);
  const alt = sliceInfo?.primary?.bakgrundsbild?.alt;
  const localLink = sliceInfo.primary?.lank?.uid;
  const externalLink = sliceInfo.primary?.lank?.raw?.url;
  const linkLang = sliceInfo.primary?.lank?.lang;
  const line = sliceInfo?.primary?.line;
  const filter = sliceInfo?.primary?.filter_select;
  const filterBool = sliceInfo?.primary?.filter;
  const language = getLanguageFromUrl();

  return (
    <section key={sliceInfo.uid} className={`tema feature-full-hero action-call-landing`}>
      {imgUrl ? (
        <div className={`relative filter-${filter} h-full ${filterBool && 'myfilter'}`}>
          <img className="heroimage" src={sliceInfo?.primary?.bakgrundsbild?.url} alt={alt} />
        </div>
      ) : null}
      <span>
        <div className="feature-text">
          <h2 className="font-medium">{sliceInfo?.primary?.title?.text}</h2>
          <p>{cutText(sliceInfo?.primary?.call_to_action, 250)}</p>
          {line ? <div className="featunderline"></div> : null}
          <Link
            to={localLink ? '/' + createLangShortcut(linkLang) + '/' + localLink : externalLink}
            target={externalLink ? '_blank' : null}
          >
            <button>{sliceInfo.primary?.knapptext ? sliceInfo.primary?.knapptext : getReadMoreString(language)}</button>
          </Link>
          <div className="store-links">
            <a target="_blank" rel="noreferrer" href={sliceInfo.primary?.android?.url}>
              <img src={android} alt="GOOGLEPLAY" />
            </a>
            <a target="_blank" rel="noreferrer" href={sliceInfo.primary?.ios?.url}>
              <img src={ios} alt="APPSTORE" />
            </a>
          </div>
        </div>
      </span>
    </section>
  );
}

ActionCall.propTypes = {
  sliceInfo: PropTypes.object.isRequired,
};
