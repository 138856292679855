import React from 'react';

import SingleImage from './SingleImage';
import './image_grid.css';

const ImageCollectionMasonry = (slice) => {
  const text = slice.primary.text.text;
  const width = slice.primary.width;
  return (
    <section className={`${width === 'boxed' ? 'content' : 'section-full-width bg-white py-20 sm:py-8 !sm:px-8' }`}>
      <div className="masonry masonry--h">
        {slice.items.map((item) => (
          <SingleImage key={Math.random() * 100} item={item} />
        ))}
      </div>
      {text ? (
        <div className="image-collection-text-wrapper">
          <p className="image-collection-text">{text}</p>
        </div>
      ) : null}
    </section>
  );
};

export default ImageCollectionMasonry;
