import React from 'react';
import PropTypes from 'prop-types';

const Line = ({slice}) => {
  return (
    <section className={'line-divider-wrapper content ' + slice?.source}>
      <div className="line-divider"></div>
    </section>
  );
};

Line.propTypes = {
  slice: PropTypes.object,
};

export default Line;
