import {graphql, useStaticQuery} from 'gatsby';
import {useMergePrismicPreviewData} from 'gatsby-plugin-prismic-previews';
import PropTypes from 'prop-types';

import {HeroFragment} from '../../fragments';
import {getLinkedArticle} from '../../utils/getLinkedArticle';
import FeatureFull from './Feature/FeatureFull';
import FeatureFullWidthImage from './Feature/FeatureFullWidthImage';
import FeatureSplitColor from './Feature/FeatureSplitColor';
import FeatureSplitColorTight from './Feature/FeatureSplitColorTight';

const Feature = ({slice}) => {
  const staticData = useStaticQuery(graphql`
    query {
      allPrismicContent {
        edges {
          node {
            uid
            _previewable
            data {
              body {
                ...HeroFragment
              }
            }
          }
        }
      }
    }
  `);
  const {primary} = slice;

  const {data, isPreview} = useMergePrismicPreviewData(staticData);

  slice = getLinkedArticle(slice, data);

  if (!primary) {
    return null;
  }

  const variant = primary.feature_variant.toLowerCase();
  switch (variant) {
    case 'split color':
      return FeatureSplitColor(slice);
    case 'split color tight':
      return FeatureSplitColorTight(slice);
    case 'full width image':
      return FeatureFullWidthImage(slice);
    default:
      return FeatureFull(slice);
  }
};

Feature.fragments = [HeroFragment];

Feature.propTypes = {
  slice: PropTypes.object,
};

export default Feature;
