import {Link} from 'gatsby';
import React from 'react';
import PropTypes from 'prop-types';

import {createLangShortcut} from '../../utils/createLangShortcut';
import './instagram.css';

const Instagram = ({slice}) => {
  const data = slice.primary;
  const uid = data.instagram_button_link.uid;
  const external = data.instagram_button_link.raw.url;
  const linkLang = data.instagram_button_link.lang;
  const alignLeft = data.knappljustering;

  return (
    <section className={slice?.source ?? 'content'}>
      <div className={alignLeft ? 'instagram-left' : 'instagram'}>
        {uid ? (
          <Link to={'/' + createLangShortcut(linkLang) + '/' + uid}>
            <button href={data.instagram_button_link.raw.url} target="_blank">
              {data.instagram_button_label}
            </button>
          </Link>
        ) : null}
        {external ? (
          <Link to={external}>
            <button href={data.instagram_button_link.raw.url} target="_blank">
              {data.instagram_button_label}
            </button>
          </Link>
        ) : null}
      </div>
    </section>
  );
};

Instagram.propTypes = {
  slice: PropTypes.object,
};

export default Instagram;
