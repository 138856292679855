const imageSizeBig = (url, size) => {
  function checkMobile() {
    if (typeof window != 'undefined') {
      let e = window;
      let a = 'inner';
      if (!('innerWidth' in window)) {
        a = 'client';
        e = document.documentElement || document.body;
      }
      if (e[a + 'Width'] < 250 && size > 400) {
        return 800;
      }
      if (e[a + 'Width'] < 500 && size > 800) {
        return 1000;
      }
      if (e[a + 'Width'] < 900 && size > 1200) {
        return 1200;
      }
    }
    return size;
  }

  if (url === undefined) {
    return undefined;
  }
  size = checkMobile();
  if (url) {
    const end = url.indexOf('%2Cformat');
    let str = url.slice(0, end);
    str = str + '%2Cformat&w=' + size;
    return str;
  }
  return url;
};

export default imageSizeBig;
