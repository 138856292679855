import PropTypes from 'prop-types';
import ActionCallBoxed from './CallToAction/ActionCallBoxed';
import ActionCallFullWidth from './CallToAction/ActionCallFullWidth';

const ActionCall = ({slice}) => {
  if (!slice.primary) {
    return null;
  }

  const variant = slice.primary.variant.toLowerCase();
  switch (variant) {
    case 'full width':
      return ActionCallFullWidth(slice);
    default:
      return ActionCallBoxed(slice);
  }
};

ActionCall.propTypes = {
  slice: PropTypes.object,
};

export default ActionCall;
