import {Link} from 'gatsby';
import PropTypes from 'prop-types';
import React from 'react';

import {createLangShortcut} from '../../../utils/createLangShortcut';
import {getLanguageFromUrl} from '../../../utils/getLanguageFromUrl';
import {getReadMoreString} from '../../../utils/getReadMoreString';
import imageSize from '../../../utils/imageSize.js';

export default function FeatureCallToAction(sliceInfo) {
  const image = sliceInfo.primary.bild?.fluid.src || sliceInfo?.image || sliceInfo.correctSlice?.primary.hero_image.url;
  const alt = sliceInfo.primary.bild?.alt;
  const imgUrl = imageSize(image, 1600);
  const title = sliceInfo.primary.rubrik?.text || sliceInfo.title || sliceInfo.correctSlice?.primary.hero_title?.text;
  const kicker = sliceInfo.primary?.feature_kicker?.text;
  const text = sliceInfo.primary?.text?.html;
  const localLink = sliceInfo.primary?.lank?.uid;
  const externalLink = sliceInfo.primary?.lank?.raw?.url;
  const linkLang = sliceInfo.primary.lank.lang;
  const line = sliceInfo?.primary?.line;
  const filter = sliceInfo?.primary?.filter || sliceInfo?.primary?.filter_select;
  const language = getLanguageFromUrl();

  return (
    <section className="tema feature-full-hero">
      <div className={`fcta-filter ${filter === 'standard' ? 'myfilter' : ''}`}>
        {imgUrl ? <img className="heroimage" src={image} alt={alt} /> : null}
        <span>
          <div className="feature-text">
            {kicker && (
              <h4
                className="text-kicker font-semibold"
                style={!image ? {color: 'black'} : null}
                dangerouslySetInnerHTML={{__html: kicker}}
              ></h4>
            )}
            <h3
              className="font-sm font-semibold"
              style={!image ? {color: 'black'} : null}
              dangerouslySetInnerHTML={{__html: title}}
            ></h3>
            {text ? <div dangerouslySetInnerHTML={{__html: text}} /> : null}
            {line ? <div className="featunderline"></div> : null}
            <Link
              to={localLink ? '/' + createLangShortcut(linkLang) + '/' + localLink : externalLink ? externalLink : null}
              target={externalLink ? '_blank' : null}
            >
              <button>{sliceInfo.primary.call_to_action || getReadMoreString(language)}</button>
            </Link>
          </div>
        </span>
      </div>
    </section>
  );
}

FeatureCallToAction.propTypes = {
  sliceInfo: PropTypes.object.isRequired,
};
