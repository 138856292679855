import {Link} from 'gatsby';
import PropTypes from 'prop-types';
import React from 'react';

import {createLangShortcut} from '../../utils/createLangShortcut';
import {cutText} from '../../utils/cutText';
import {getLanguageFromUrl} from '../../utils/getLanguageFromUrl';
import {getReadMoreString} from '../../utils/getReadMoreString';
import imageSize from '../../utils/imageSize.js';

const Quote = ({slice}) => {
  const image = slice.primary.bild.fixed?.src;
  const alt = slice.primary?.bild?.alt;
  const imgUrl = imageSize(image, 1600);
  const localLink = slice.primary.lank.uid;
  const externalLink = slice.primary.lank.raw.url;
  const linkLang = slice.primary.lank.lang;
  const line = slice?.primary?.line;
  const filter = slice?.primary?.filter;
  const data = slice.primary;
  const language = getLanguageFromUrl();

  return (
    <section className={data?.source ?? 'tema'}>
      <div className="feature-split-color">
        <div className="feature-content">
          <div className="feature-content-inner">
            <h3 className="font-sm">{data.citat}</h3>
            <p>{cutText(data.byline, 250)}</p>
            {line ? <div className="featunderline"></div> : null}
            {localLink ? (
              <Link to={'/' + createLangShortcut(linkLang) + '/' + slice.primary.lank.uid}>
                <button>{data?.call_to_action || getReadMoreString(language)}</button>
              </Link>
            ) : externalLink ? (
              <a href={externalLink} target="_blank" rel="noreferrer">
                <button>{data?.call_to_action || getReadMoreString(language)}</button>
              </a>
            ) : null}
            <div className="featunderline"></div>
          </div>
        </div>
        <div className={`feature-img ${filter === 'standard' ? 'filter-standard' : ''}`}>
          {imgUrl ? <img className="quote-img" src={image} alt={alt}></img> : null}
        </div>
      </div>
    </section>
  );
};

Quote.propTypes = {
  slice: PropTypes.object.isRequired,
};

export default Quote;
