import {Link} from 'gatsby';
import React from 'react';

import android from '../../../images/android-small.png';
import ios from '../../../images/ios-small.png';
import {createLangShortcut} from '../../../utils/createLangShortcut';
import imageSizeBig from '../../../utils/imageSizeBig.js';
import './herosplit.css';

export default function HeroSplitApp(slice) {
  const image = slice.primary.hero_image?.fluid?.src;
  const alt = slice.primary.hero_image?.alt;
  const imgUrl = imageSizeBig(image, 1600);

  const imgOverlay = slice.primary.hero_overlay_image.fluid?.src;
  const extraTitle = slice.primary?.extra_title?.text;
  const localLink = slice.primary.lank.uid;
  const externalLink = slice.primary.lank.raw.url;
  const linkLang = slice.primary.lank.lang;
  const additionalLocalLink = slice.primary?.ytterligare_lank?.uid;
  const additionalExternalLink = slice.primary?.ytterligare_lank?.url;
  const additionalLinkLang = slice.primary?.ytterligare_lank?.lang;
  const action = slice?.primary?.action?.text;
  const additionalAction = slice?.primary?.ytterligare_action?.text;
  const vimeoVideo = slice?.primary?.vimeo_video?.embed_url;
  const filter = slice?.primary?.filter_select;
  let segments = [];
  let videoId = '';
  if (vimeoVideo) {
    segments = vimeoVideo.split('/');
    videoId = segments[segments.length - 1];
  }

  return (
    <section className={'hero hero-split hero-split-app'} key={Math.random()}>
      <div className="hero-split-wrapper">
        <div className="hero-text-split-wrapper pt-5">
          <div className={imgOverlay ? 'hero-text-split hero-overlay-split section-px' : 'hero-text-split section-px'}>
            {extraTitle ? <span className="hero-extra-title">{extraTitle}</span> : null}
            <h1>{slice.primary.hero_title[0]?.text || slice.primary.hero_title.text}</h1>
            <p>{slice.primary.hero_introduction[0]?.text || slice.primary.hero_introduction.text}</p>
            <div className="d-block cta-buttons">
              {localLink ? (
                <Link to={'/' + createLangShortcut(linkLang) + '/' + localLink}>
                  <button>{action}</button>
                </Link>
              ) : externalLink ? (
                <a href={externalLink} target="_blank" rel="noreferrer">
                  <button>{action}</button>
                </a>
              ) : null}
              {additionalLocalLink ? (
                <Link to={'/' + createLangShortcut(additionalLinkLang) + '/' + additionalLocalLink}>
                  <button>{additionalAction}</button>
                </Link>
              ) : additionalExternalLink ? (
                <a href={additionalExternalLink} target="_blank" rel="noreferrer">
                  <button>{additionalAction}</button>
                </a>
              ) : null}
            </div>
            <div className="app-links">
              <a target="_blank" rel="noreferrer" href={slice.primary?.android?.url}>
                <img src={android} alt="GOOGLEPLAY" />
              </a>
              <a target="_blank" rel="noreferrer" href={slice.primary?.ios?.url}>
                <img src={ios} alt="APPSTORE" />
              </a>
            </div>
          </div>
        </div>
        <div className={`hero-img-split-wrapper filter-${filter}`}>
          {imgUrl ? <img src={image} alt={alt} className="heroimagesplit" /> : <div className="heroimagesplit" />}
          {slice.primary?.hero_video ? (
            <video autoPlay muted loop id="myVideo">
              <source src={slice.primary.hero_video} type="video/mp4" />
              Your browser does not support HTML5 video.
            </video>
          ) : null}
          {vimeoVideo ? (
            <div className="vimeo-video-wrapper">
              <iframe
                title={slice.primary.hero_title[0]?.text || slice.primary.hero_title.text}
                // eslint-disable-next-line max-len
                src={`https://player.vimeo.com/video/${videoId}?speed=0&autoplay=1&controls=0&background=1&quality=720p&loop=1&muted=1}`}
                width="640"
                height="360"
                frameBorder="0"
                webkitallowfullscreen
                mozallowfullscreen
                allowFullScreen
              ></iframe>
            </div>
          ) : null}
        </div>
      </div>
    </section>
  );
}
