import React from 'react';
import PropTypes from 'prop-types';

const Author = ({slice}) => {
  const author = slice.primary?.forfattare?.document?.data;
  return (
    <>
      <section className={'author-section '} key={Math.random()}>
        <div className="author-left">
          {author ? <img className="hero-author" src={author.editor_image.url} alt=""></img> : null}
        </div>
        <div className="author-right">
          {author ? <p className="author-name">{author.editor_name}</p> : null}
          {author ? (
            <p className="author-title" style={{color: 'LightGray'}}>
              {author.editor_role}
            </p>
          ) : null}
        </div>
      </section>
    </>
  );
};

Author.propTypes = {
  slice: PropTypes.object,
};

export default Author;
