import PropTypes from 'prop-types';
import SectionHeaderCentered from './SectionHeader/SectionHeaderCentered';
import SectionHeaderLeftAligned from './SectionHeader/SectionHeaderLeftAligned';
import SectionHeaderLeftAlignedFullWidth from './SectionHeader/SectionHeaderLeftAlignedFullWidth';
import SectionHeaderLine from './SectionHeader/SectionHeaderLine';
import SectionHeaderMixedSolid from './SectionHeader/SectionHeaderMixedSolid';
import SectionHeaderMixedWavy from './SectionHeader/SectionHeaderMixedWavy';
import SectionHeaderWide from './SectionHeader/SectionHeaderWide';

const SectionHeader = ({slice}) => {
  const {primary} = slice;

  if (!primary) {
    return null;
  }

  const variant = primary.section_header_variant.toLowerCase();

  switch (variant) {
    case 'centered':
      return SectionHeaderCentered(slice);
    case 'line':
      return SectionHeaderLine(slice);
    case 'wide':
      return SectionHeaderWide(slice);
    case 'mixed wavy':
      return SectionHeaderMixedWavy(slice);
    case 'mixed solid':
      return SectionHeaderMixedSolid(slice);
    case 'left aligned full width':
      return SectionHeaderLeftAlignedFullWidth(slice);
    default:
      return SectionHeaderLeftAligned(slice);
  }
};

SectionHeader.propTypes = {
  slice: PropTypes.object,
};

export default SectionHeader;
