import PropTypes from 'prop-types';
import React from 'react';

const Video = ({slice}) => {
  return (
    <section className="video content">
      <div dangerouslySetInnerHTML={{__html: slice.primary.youtube?.html || slice.primary.vimeo?.html}} />
    </section>
  );
};

Video.propTypes = {
  slice: PropTypes.object,
};

export default Video;
