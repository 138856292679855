import PropTypes from 'prop-types';
import React from 'react';

import androidMobile from '../../../images/android-mobile.png';
import android from '../../../images/android.png';
import iosMobile from '../../../images/ios-mobile.png';
import ios from '../../../images/ios.png';

export default function ActionCallFullWidth(sliceInfo) {
  const img = sliceInfo.primary?.bakgrundsbild?.url;
  const title = sliceInfo.primary?.title?.text;
  const description = sliceInfo.primary?.call_to_action;
  const androidLink = sliceInfo.primary?.android?.url;
  const iosLink = sliceInfo.primary?.ios?.url;
  const filter = sliceInfo.primary?.filter_select;
  const filterBool = sliceInfo.primary?.filter;

  return (
    <section key={sliceInfo.uid} className="section-full-width pt-10 md:pt-20 bg-green-accent">
      <div className="flex sm:flex-wrap">
        <div className="w-1/2 sm:w-full flex flex-col justify-center">
          <h2 className="text-3xl md:text-3xl text-black font-semibold uppercase mb-3 mt-0 px-4">{title}</h2>
          <div className="text-base text-black mb-10 font-fira px-4">{description}</div>
          <div className="sm:justify-center hidden md:flex">
            <a className="mr-5" target="_blank" rel="noreferrer" href={androidLink}>
              <img src={android} alt="GOOGLEPLAY" />
            </a>
            <a target="_blank" rel="noreferrer" href={iosLink}>
              <img src={ios} alt="APPSTORE" />
            </a>
          </div>
          <div className="flex sm:justify-center md:hidden">
            <a className="mr-5" target="_blank" rel="noreferrer" href={androidLink}>
              <img src={androidMobile} alt="GOOGLEPLAY" />
            </a>
            <a target="_blank" rel="noreferrer" href={iosLink}>
              <img src={iosMobile} alt="APPSTORE" />
            </a>
          </div>
        </div>
        <div className={`w-1/2 sm:w-full flex items-end justify-center sm:mt-8 pt-10 md:pt-0 relative h-96 md:h-500`}>
          <div className={`h-full filter-${filter} ${filterBool && 'myfilter'}`}>
            <img className="h-full object-contain object-bottom" src={img} alt="" />
          </div>
        </div>
      </div>
    </section>
  );
}

ActionCallFullWidth.propTypes = {
  sliceInfo: PropTypes.object.isRequired,
};
