import {graphql, useStaticQuery} from 'gatsby';
import {useMergePrismicPreviewData} from 'gatsby-plugin-prismic-previews';
import PropTypes from 'prop-types';

import {HeroFragment} from '../../fragments';
import {getCollectionArticles} from '../../utils/getCollectionArticles';
import CollectionAlternatingLargeList from './Collection/CollectionAlternatingLargeList';
import CollectionAlternatingSmallList from './Collection/CollectionAlternatingSmallList';
import CollectionFullWidth2Columns from './Collection/CollectionFullWidth2Columns';
import CollectionFullWidth2Items from './Collection/CollectionFullWidth2Items';
import CollectionFullWidth3Items from './Collection/CollectionFullWidth3Items';
import CollectionFullWidth4Items from './Collection/CollectionFullWidth4Items';
import CollectionGridLarge from './Collection/CollectionGridLarge';
import CollectionGridLarge3PerRow from './Collection/CollectionGridLarge3PerRow';
import CollectionGridSmall from './Collection/CollectionGridSmall';
import CollectionListAlternating from './Collection/CollectionListAlternating';
import CollectionListLarge from './Collection/CollectionListLarge';
import CollectionListSmall from './Collection/CollectionListSmall';

const Collection = ({slice}) => {
  const staticData = useStaticQuery(graphql`
    query {
      allPrismicContent {
        edges {
          node {
            uid
            _previewable
            data {
              body {
                ...HeroFragment
              }
            }
          }
        }
      }
    }
  `);

  const {primary} = slice;

  const {data, isPreview} = useMergePrismicPreviewData(staticData);

  slice = getCollectionArticles(slice, data);

  if (!primary) {
    return null;
  }

  const variant = primary.collection_variant.toLowerCase();

  switch (variant) {
    case 'large list':
      return CollectionListLarge(slice);
    case 'small grid':
      return CollectionGridSmall(slice);
    case 'alternating list':
      return CollectionListAlternating(slice);
    case 'alternating small list':
      return CollectionAlternatingSmallList(slice);
    case 'alternating large list':
      return CollectionAlternatingLargeList(slice);
    case 'large grid':
      return CollectionGridLarge(slice);
    case 'large grid 3 per row':
      return CollectionGridLarge3PerRow(slice);
    case 'full width 2 columns':
      return CollectionFullWidth2Columns(slice);
    case 'full width 2 items':
      return CollectionFullWidth2Items(slice);
    case 'full width 3 items':
      return CollectionFullWidth3Items(slice);
    case 'full width 4 items':
      return CollectionFullWidth4Items(slice);
    default:
      return CollectionListSmall(slice);
  }
};

Collection.fragments = [HeroFragment];

Collection.propTypes = {
  slice: PropTypes.object,
};

export default Collection;
